import React from "react";
import { Dropdown, NavDropdown } from "react-bootstrap";

const CommonDropdown = ({
  items = [],
  avatar = null,
  title = "...",
  dropdownType = "default", // 'default' or 'user'
  onSelect,
}) => {
  if (dropdownType === "user") {
    return (
      <NavDropdown
        title={
          <div>
            {avatar && <img className="user-dropdown-image" src={avatar} alt="User Avatar" />}
            <span>{title}</span>
          </div>
        }
        id="basic-nav-dropdown"
      >
        {items.map(({ label, action, href }, idx) => (
          <NavDropdown.Item
            key={idx}
            href={href || "#"}
            onClick={() => action && onSelect(action)}
          >
            {label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  }

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="no-caret">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map(({ label, action, Icon }, idx) => (
          <Dropdown.Item key={idx} onClick={() => onSelect(action)}>
            {Icon && <span>{<Icon />}</span>} {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CommonDropdown;
