import React from "react";
import CommonDropdown from "../common/CommonDropdowns/CommonDropdown";

const UserDropdown = ({ avatar, onLogout, handleAccount }) => (
  <CommonDropdown
    dropdownType="user"
    avatar={avatar}
    title="Admin"
    items={[
      { label: "Accounts", action: "account" },
      { label: "Logout", action: "logout" },
    ]}
    onSelect={(action) => {
      if (action === "logout") {
        onLogout();
      }else if(action === "account"){
        handleAccount()
      }
    }}
  />
);

export default UserDropdown;
