import React from "react";

const ConferenceSection = () => {
  const conferences = [
    {
      title: "Tech Innovators 2023",
      topic: "Revolutionizing User Experience with AI-driven Interfaces",
      date: "September 15, 2023",
      venue: "Moscone Center, San Francisco, CA",
    },
  ];

  return (
      <div className="conference-event">
        <div className="heading-conference-event">CONFERENCE EVENT</div>
        <div className="conference-event-data">
          {conferences.map(({ title, topic, date, venue }, idx) => (
            <div className="history" key={idx}>
              <div className="left-div">
                <div className="ordered-list-dot"></div>
              </div>
              <div className="right-div">
                <div className="title">{title}</div>
                <div className="topic-name">
                  <span className="key">Topic:</span>
                  <span className="value">{topic}</span>
                </div>
                <div className="event-date">
                  <span className="key">Event Date:</span>
                  <span className="value">{date}</span>
                </div>
                <div className="venue">
                  <span className="key">Venue:</span>
                  <span className="value">{venue}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default ConferenceSection;
