import { React, useState} from "react";
import {
  AiFillPlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";

import { AddLocationPopup } from "../../components/popups/AddLocationPopup";
import { AddBranchPopup } from "../../components/popups/AddBranchPopup";
import { BranchDetailPopup } from "../../components/popups/BranchDetailPopup";
import { DeleteLocationPopup } from "../../components/popups/DeleteLocationPopup";
import {
  useGetOrganisationLocationsQuery,
  useCreateLocationMutation,
  useDeleteBranchMutation,
  useCreateBranchMutation,
  useDeleteLocationMutation,
} from "../../features/users/usersApiSlice";
import LocationTree from "../../components/sitemapMobile";

import "./styles.scss";

const Map = () => {
  const [showAddLocationPopup, setShowAddLocationPopup] = useState(false);
  const [showAddBranchPopup, setShowAddBranchPopup] = useState(false);
  const [showBranchDetailsPopup, setShowBranchDetailsPopup] = useState(false);
  const [showDeleteLocationPopup, setShowDeleteLocationPopup] = useState(false);
  const [newLocationDirectoryIndex, setNewLocationDirectoryIndex] = useState(0);
  const [newLocationParent, setNewLocationParent] = useState("");
  const [deleteLocationDirectoryIndex, setDeleteLocationDirectoryIndex] =
    useState(0);
  const [deleteLocationName, setDeleteLocationName] = useState("");
  const [deleteLocationId, setDeleteLocationId] = useState("");
  const [branchDetailsData, setBranchDetailsData] = useState({});
  const [newBranchLocationName, setNewBranchLocationName] = useState("");
  const [newBranchLocationId, setNewBranchLocationId] = useState("");



  const {
    data: locationData,
    refetch: refetch_locations,
  } = useGetOrganisationLocationsQuery({ parent_id: null });

  const [createLocation] = useCreateLocationMutation();

  const [createBranch] = useCreateBranchMutation();

  const [deleteLocation] = useDeleteLocationMutation();
  const [deleteBranch] = useDeleteBranchMutation();

  const onAddLocationPopupClose = () => {
    setNewLocationParent("");
    setNewLocationDirectoryIndex(0);
    setShowAddLocationPopup(false);
  };
  const SubmitAddBranchHandler = async (id, name) => {
    setNewBranchLocationId(id);
    setNewBranchLocationName(name);
    setShowAddBranchPopup(true);
  };

 

  const onAddBranchPopupClose = () => {
    setNewBranchLocationName("");
    setNewBranchLocationId("");
    setShowAddBranchPopup(false);
  };
  const onBranchDetailsPopupClose = () => {
    setBranchDetailsData("");
    setShowBranchDetailsPopup(false);
  };


  const SubmitBranchDetailHandler = async (data) => {
    setBranchDetailsData(data);
    setShowBranchDetailsPopup(true);
  };

  const SubmitAddBranchPopupHandler = async ({
    branchName,
    branchAddress,
    branchPhone,
  }) => {
    
    const data = {
      location_fk: newBranchLocationId,
      branch_name_nn: branchName,
      branch_address: branchAddress,
      branch_phone: branchPhone,
    };
   
    const response = await createBranch(data);
    if (response.data) {
      refetch_locations();
    }
  };

  const SubmitAddLocationHandler = async (level, parent) => {
    setNewLocationParent(parent);
    setNewLocationDirectoryIndex(level);
    setShowAddLocationPopup(true);
  };
  const SubmitDeleteLocationHandler = async (id, level, name) => {
    setDeleteLocationName(name);
    setDeleteLocationId(id);
    setDeleteLocationDirectoryIndex(level);
    setShowDeleteLocationPopup(true);
  };

  const onDeleteLocationPopupClose = () => {
    setDeleteLocationName("");
    setDeleteLocationId("");
    setDeleteLocationDirectoryIndex(0);
    setShowDeleteLocationPopup(false);
  };

  const SubmitDeleteLocationPopupHandler = async () => {
    const data = {
      location_id: deleteLocationId,
    };

    const response = await deleteLocation(data);
    if (response.data) {
      refetch_locations();
    }
  };
  const SubmitAddLocationPopupHandler = async (name) => {
    const data = {
      name_nn: name,
      level: newLocationDirectoryIndex + 1,
      parent_fk: newLocationParent,
    };

    const response = await createLocation(data);
    if (response.data) {
      refetch_locations();
    }
  };
  const SubmitBranchDetailsPopupHandler = async () => {
    const data = {
      branch_id: branchDetailsData.branch_id,
    };

    const response = await deleteBranch(data);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
  };

  

  const getLevelName = () => {
    const count = newLocationDirectoryIndex + 1;
    switch (count) {
      case 0:
        return "Global";
      case 1:
        return "Continent";
      case 2:
        return "Country";
      case 3:
        return "State";
      case 4:
        return "City";
      case 5:
        return "Local";
      default:
    }
  };



  return (
    <section className="layout-inner">
      <div className="organisation-map-outer">
        <div className="organisation-location-outer">
          <div className="data-outer-heading">
            Address Hierarchy
          </div>
          <LocationTree
            data={locationData?.data[0]}
            SubmitAddLocationHandler={SubmitAddLocationHandler}
            SubmitDeleteLocationHandler={SubmitDeleteLocationHandler}
            SubmitAddBranchHandler={SubmitAddBranchHandler}
            SubmitBranchDetailHandler={SubmitBranchDetailHandler}
          />
          <div className="organisation-location-inner">
            {locationData &&
              locationData.data &&
              locationData.data.map((globalItem,index) => {
                return (
                  <div key={index} className="row location-data-outer">
                    <div className="organisation-location-headers-outer row">
                      <div className="col-2 organisation-location-header">Global</div>
                      <div className="col-2 organisation-location-header">Continent</div>
                      <div className="col-2 organisation-location-header">Country</div>
                      <div className="col-2 organisation-location-header">State</div>
                      <div className="col-2 organisation-location-header">City</div>
                      <div className="col-2 organisation-location-header">Local</div>
                    </div>
                    <hr />

                    <div className="col-2 location-data-inner">
                      <div className="location-data-container">
                        <div className="location-name">{globalItem.name_nn}</div>
                      
                      </div>
                    </div>
                    <div className="col-2 location-data-inner">
                      <button
                        className="add-location"
                        onClick={() => {
                          SubmitAddLocationHandler(0, globalItem.location_id);
                        }}
                      >
                        Add Continent{" "}
                        <span>
                          <AiFillPlusCircle />
                        </span>
                      </button>
                    </div>
                    {globalItem.sublocations.map(
                      (continentItem, continenetIndex) => {
                        return (
                          <div
                          key={continenetIndex}
                            className={
                              continenetIndex <
                                globalItem.sublocations.length - 1
                                ? "row side-marker-continent"
                                : "row"
                            }
                          >
                            <div className="col-2 curve-marker curve-marker-continent">
                              <div className="curve curve-continent"></div>
                            </div>
                            <div className="col-2 location-data-inner">
                              <div className="location-data-container">
                                <div className="location-name">
                                  {continentItem.name_nn}
                                </div>
                                <div className="location-func-btns">

                                  <button
                                    className="remove-location"
                                    onClick={() => {
                                      SubmitDeleteLocationHandler(
                                        continentItem.location_id,
                                        1,
                                        continentItem.name_nn
                                      );
                                    }}
                                  >
                                    <AiOutlineMinusCircle />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 location-data-inner">
                              <button
                                className="add-location"
                                onClick={() => {
                                  SubmitAddLocationHandler(1, continentItem.location_id);
                                }}
                              >
                                Add Country{" "}
                                <span>
                                  <AiFillPlusCircle />
                                </span>
                              </button>
                            </div>
                            {continentItem.sublocations.map(
                              (countryItem, countryIndex) => {
                                return (
                                  <div
                                  key={countryIndex}
                                    className={
                                      countryIndex <
                                        continentItem.sublocations.length - 1
                                        ? "row side-marker-country"
                                        : "row"
                                    }
                                  >
                                    <div className="col-4 curve-marker curve-marker-country">
                                      <div className="curve curve-country"></div>
                                    </div>
                                    <div className="col-2 location-data-inner">
                                      <div className="location-data-container">
                                        <div className="location-name">
                                          {countryItem.name_nn}
                                        </div>
                                        <div className="location-func-btns">
                                      
                                          <button
                                            className="remove-location"
                                            onClick={() => {
                                              SubmitDeleteLocationHandler(
                                                countryItem.location_id,
                                                2,
                                                countryItem.name_nn
                                              );
                                            }}
                                          >
                                            <AiOutlineMinusCircle />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 location-data-inner">
                                      <button
                                        className="add-location"
                                        onClick={() => {
                                          SubmitAddLocationHandler(
                                            2,
                                            countryItem.location_id
                                          );
                                        }}
                                      >
                                        Add State{" "}
                                        <span>
                                          <AiFillPlusCircle />
                                        </span>
                                      </button>
                                    </div>
                                    {countryItem.sublocations.map(
                                      (stateItem, stateIndex) => {
                                        return (
                                          <div
                                          key={stateIndex}
                                            className={
                                              stateIndex <
                                                countryItem.sublocations.length -
                                                1
                                                ? "row side-marker-state"
                                                : "row"
                                            }
                                          >
                                            <div className="col-6 curve-marker curve-marker-state">
                                              <div className="curve curve-state"></div>
                                            </div>
                                            <div className="col-2 location-data-inner">
                                              <div className="location-data-container">
                                                <div className="location-name">
                                                  {stateItem.name_nn}
                                                </div>
                                                <div className="location-func-btns">
                                  
                                                  <button
                                                    className="remove-location"
                                                    onClick={() => {
                                                      SubmitDeleteLocationHandler(
                                                        stateItem.location_id,
                                                        3,
                                                        stateItem.name_nn
                                                      );
                                                    }}
                                                  >
                                                    <AiOutlineMinusCircle />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-2 location-data-inner">
                                              <button
                                                className="add-location"
                                                onClick={() => {
                                                  SubmitAddLocationHandler(
                                                    3,
                                                    stateItem.location_id
                                                  );
                                                }}
                                              >
                                                Add City{" "}
                                                <span>
                                                  <AiFillPlusCircle />
                                                </span>
                                              </button>
                                            </div>
                                            {stateItem.sublocations.map(
                                              (cityItem, cityIndex) => {
                                                return (
                                                  <div
                                                  key={cityIndex}
                                                    className={
                                                      cityIndex <
                                                        stateItem.sublocations
                                                          .length -
                                                        1
                                                        ? "row side-marker-city"
                                                        : "row"
                                                    }
                                                  >
                                                    <div className="col-8 curve-marker curve-marker-city">
                                                      <div className="curve curve-city"></div>
                                                    </div>
                                                    <div className="col-2 location-data-inner">
                                                      <div className="location-data-container">
                                                        <div className="location-name">
                                                          {cityItem.name_nn}
                                                        </div>
                                                        <div className="location-func-btns">
                                        

                                                          <button
                                                            className="remove-location"
                                                            onClick={() => {
                                                              SubmitDeleteLocationHandler(
                                                                cityItem.location_id,
                                                                4,
                                                                cityItem.name_nn
                                                              );
                                                            }}
                                                          >
                                                            <AiOutlineMinusCircle />
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-2 location-data-inner">
                                                      <button
                                                        className="add-location"
                                                        onClick={() => {
                                                          SubmitAddLocationHandler(
                                                            4,
                                                            cityItem.location_id
                                                          );
                                                        }}
                                                      >
                                                        Add Local{" "}
                                                        <span>
                                                          <AiFillPlusCircle />
                                                        </span>
                                                      </button>
                                                    </div>
                                                    {cityItem.sublocations.map(
                                                      (
                                                        localItem,
                                                        localIndex
                                                      ) => {
                                                        return (
                                                          <div
                                                          key={localIndex}
                                                            className={
                                                              localIndex <
                                                                cityItem
                                                                  .sublocations
                                                                  .length -
                                                                1
                                                                ? "row side-marker-local"
                                                                : "row"
                                                            }
                                                          >
                                                            <div className="col-10 curve-marker curve-marker-local">
                                                              <div className="curve curve-local"></div>
                                                            </div>
                                                            <div className="col-2 location-data-inner">
                                                              <div className="location-data-container">
                                                                <div className="location-name">
                                                                  {
                                                                    localItem.name_nn
                                                                  }
                                                                </div>
                                                                <div className="location-func-btns">
                                                                 
                                                                  <button
                                                                    className="remove-location"
                                                                    onClick={() => {
                                                                      SubmitDeleteLocationHandler(
                                                                        localItem.location_id,
                                                                        5,
                                                                        localItem.name_nn
                                                                      );
                                                                    }}
                                                                  >
                                                                    <AiOutlineMinusCircle />
                                                                  </button>
                                                                </div>
                                                              </div>
                                                              {localItem
                                                                .branches
                                                                .length < 1 ? (
                                                                <button
                                                                  className="add-branch-outer"
                                                                  onClick={() => {
                                                                    SubmitAddBranchHandler(
                                                                      localItem.location_id,
                                                                      localItem.name_nn
                                                                    );
                                                                  }}
                                                                >
                                                                  <div className="add-branch-inner">
                                                                    Add Branch
                                                                  </div>
                                                                </button>
                                                              ) : (
                                                                <div className="branches-outer">
                                                                  <div className="branches-heading">
                                                                    Branches
                                                                  </div>
                                                                  {localItem.branches.map(
                                                                    (item,branchIndex) => {
                                                                      return (
                                                                        <div key={branchIndex}>
                                                                        <button
                                                                          className="branch"
                                                                          onClick={() => {
                                                                            SubmitBranchDetailHandler(
                                                                              item
                                                                            );
                                                                          }}
                                                                        >
                                                                          <div className="branch-name">
                                                                            {
                                                                              item.branch_name_nn
                                                                            }
                                                                          </div>
                                                                        </button>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  )}
                                                                  <div className="add-branch-outer">
                                                                    <button
                                                                      className="add-branch-inner"
                                                                      onClick={() => {
                                                                        SubmitAddBranchHandler(
                                                                          localItem.location_id,
                                                                          localItem.name_nn
                                                                        );
                                                                      }}
                                                                    >
                                                                      Add Branch
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        
      </div>
      <AddLocationPopup
        level={getLevelName(newLocationDirectoryIndex)}
        show={showAddLocationPopup}
        onSubmit={SubmitAddLocationPopupHandler}
        onClose={onAddLocationPopupClose}
      />
      <AddBranchPopup
        name={newBranchLocationName}
        show={showAddBranchPopup}
        onSubmit={SubmitAddBranchPopupHandler}
        onClose={onAddBranchPopupClose}
      />
      <BranchDetailPopup
        data={branchDetailsData}
        show={showBranchDetailsPopup}
        onSubmit={SubmitBranchDetailsPopupHandler}
        onClose={onBranchDetailsPopupClose}
      />
     
      <DeleteLocationPopup
        name={deleteLocationName}
        level={getLevelName(deleteLocationDirectoryIndex)}
        show={showDeleteLocationPopup}
        onSubmit={SubmitDeleteLocationPopupHandler}
        onClose={onDeleteLocationPopupClose}
      />
    </section>
  );
};

export default Map;
