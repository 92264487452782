import React from "react";

const Heading = ({ text }) => (
  <div className="page-heading-outer">
    <div className="page-heading">{text}</div>
  </div>
);

const LogoAndName = ({ logo, name }) => (
  <div className="company-logo-outer">
    <img alt="Antro-Logo" src={logo} className="company-logo" />
    <div className="organisation-name">{name}</div>
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className={label.toLowerCase().replace(/\s+/g, "-")}>
    <span className="label">
      <span>{label}</span>
      <span>:</span>
    </span>{" "}
    {value}
  </div>
);

export { Heading, LogoAndName, DetailRow };
