import { apiSlice } from "../../app/api/apiSlice";
import { toast } from "react-toastify";
import { useRef } from "react";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/accounts/corporate_login/',
                method: 'post',
                data: { ...credentials }
            })
        }),
    })
})


export const {
    useLoginMutation
} = authApiSlice