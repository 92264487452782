import React, { useEffect} from 'react';
import './organisations.scss'; 
import { useNavigate } from 'react-router-dom'; 
import {useGetUserOrganisationsQuery } from "../../features/users/usersApiSlice";


const Organisations = () => {
  const navigate = useNavigate(); 

  const {
    data: organisation_members,
    refetch,
  } = useGetUserOrganisationsQuery();

  const handleCardClick = (organisation_id) => {
    navigate(`/sitemap/${organisation_id}/`); 
  };
  useEffect(()=>{
    refetch()
  },[ refetch])


  return (
    <div className="organisations-container">
      <h2>Your Organisations</h2>
      <div className="apps-grid grid">
          {organisation_members && organisation_members.data.map((app, index) => (
            <div 
              key={index}
              className="app-card"
              data-color={app.color}
              onClick={() => handleCardClick(app.organisation_id)} 
            >
              <div 
                className="app-icon"
                style={{ backgroundColor: app.color }}
              >
                <img src={app.logo} alt="iconimage"/>
              </div>
              <div className="app-details">
                <h3>{app.name_nn}</h3>
                <p>{app.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Organisations;
