import React from "react";

const ProjectsSection = () => {
  const projects = [
    {
      title: "SmartHome Automation",
      description:
        "Developed a smart home system that integrates IoT devices to provide automated control and monitoring via a mobile app.",
    },
    {
      title: "Eco-friendly Ride Sharing App",
      description:
        "Created a ride-sharing platform with a focus on reducing carbon footprint by suggesting eco-friendly routes and vehicle-sharing options.",
    },
  ];

  return (
      <div className="projects">
        <div className="heading-projects">PROJECTS</div>
        <div className="projects-data">
          {projects.map(({ title, description }, idx) => (
            <div className="history" key={idx}>
              <div className="left-div">
                <div className="icon-projects">
                  <div className="ordered-list-dot"></div>
                </div>
              </div>
              <div className="right-div">
                <div className="dot-projects"></div>
                <div className="title">{title}</div>
                <div className="description">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default ProjectsSection;
