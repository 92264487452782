import React from "react";
import Avatar from "../../../assets/images/avatar.png";

const AvatarComponent = ({ userData }) => {

  const firstName = userData?.data?.first_name || "";
  const lastName = userData?.data?.last_name || "";
  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <div className="member-details-part-one">
      <div className="left-part">
        <div className="member-picture">
          <img src={userData?.data?.basic_user_info?.image || Avatar} alt="User Avatar" />
        </div>
        <div className="name-designation">
          <span className="member-name-header">
           {fullName
                 ? `${
                       firstName.charAt(0).toUpperCase() + firstName.slice(1)
                     } ${lastName.charAt(0).toUpperCase() + lastName.slice(1)}`
                   : "Not available"}
          </span>
          <span className="member-designation-header">Data Analyst</span>
        </div>
      </div>
    </div>
  );
};

export default AvatarComponent;