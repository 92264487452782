import Popup from "reactjs-popup";
import { useState, useEffect } from "react";
import Select, { components, MenuListProps } from "react-select";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import "./styles/add_branch.scss";
import { continents, countries, languages } from 'countries-list'


export const AddBranchPopup = ({ name, show, onSubmit, onClose }) => {
  const [branchName, setBranchName] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const user = useSelector(selectCurrentUser);
  const [country, setCountry] = useState({
    value: 91,
    label: "India (+91)"
  })
  const [countriesList, setCountriesList] = useState([])


  const handleBranchNameInput = (e) => {
    setBranchName(e.target.value);
  };
  const handleBranchAddressInput = (e) => {
    setBranchAddress(e.target.value);
  };
  const handleBranchPhoneInput = (e) => {
    if (e.target.value.toString().length > 10) {
      return
    }
    setBranchPhone(e.target.value);
  };


  const submitHandle = () => {
    const phone = `+${country.value}${branchPhone}`
    onSubmit({branchName, branchAddress, branchPhone: phone});
    onClose();
    setBranchName("");
    setBranchAddress("")
    setBranchPhone("")
  };

  const setCountryHandler = (e) => {
    setCountry(e)
  }

  useEffect(() => {
    const temp_countries = Object.entries(countries).map(([code, country]) => ({
      label: `${country.name} (+${country.phone[0].toString()})`,
      value: country.phone[0].toString()
    }));
    setCountriesList(temp_countries)
  }, []);


  return (
    <div>
      <Popup
        modal
        className="add-branch-popup"
        open={show}
        onClose={() => {
          onClose();
          setBranchName("");
          setBranchAddress("")
          setBranchPhone("")
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
            setBranchName("");
            setBranchAddress("")
            setBranchPhone("")
          }}
        >
          x
        </button>
        <div className="add-branch-outer">
          <div className="popup-heading">Add branch</div>
          <div className="popup-content-inner">
            <div className="branch-name-outer">
              <div className="name-label">
                Please enter the name of the branch to be created under {name}
              </div>
              <input
                className="branch-name-input"
                onChange={handleBranchNameInput}
                value={branchName}
                required
              />
            </div>
            <div className="branch-name-outer">
              <div className="name-label">
                Please enter the address
              </div>
              <input
                className="branch-name-input"
                onChange={handleBranchAddressInput}
                value={branchAddress}
                required
              />
            </div>
            <div className="branch-name-outer">
              <div className="name-label">
                Please enter the phone number
              </div>
              <div style={{display: 'flex'}}>
              <Select
                    isSearchable={false}
                    className="dropdown-item"
                    styles={{width: "30%"}}
                    value={country}
                    onChange={setCountryHandler}
                    options={countriesList}
                  />
              <input
                className="branch-name-input"
                type="number"
                max='99999999999'
                onChange={handleBranchPhoneInput}
                value={branchPhone}
                required
              />
              </div>
            </div>
          </div>
          <div className="popup-content-functionality-btn">
            <button
              className={
                (branchName !== "" && branchAddress !== "" && branchPhone !== "")
                  ? "submit-btn"
                  : "submit-btn submit-btn-disabled"
              }
              onClick={submitHandle}
            >
              Add
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
