import React from "react";
import CertificatesIcon from "../../../assets/images/member-details/certificates-icon.svg";

const CertificationSection = () => {
  const certifications = [
    {
      title: "AWS Certified Solutions Architect – Associate",
      issued: "August 2022",
      id: "ABC123456789",
    },
    {
      title: "Certified Scrum Master (CSM)",
      issued: "March 2021",
      id: "XYZ987654321",
    },
  ];

  return (
        <div className="left-lower-div">
          <div className="heading-certifications">CERTIFICATIONS</div>
          <div className="certification-data">
            {certifications.map(({ title, issued, id }, idx) => (
              <div className="history" key={idx}>
                <div className="left-div">
                  <div className="icon-certification">
                    <img src={CertificatesIcon} alt="Certificate Icon" />
                  </div>
                </div>
                <div className="right-div">
                  <div className="certified-course">{title}</div>
                  <div className="issued-year">
                    <span className="key">Issued:</span>{" "}
                    <span className="value">{issued}</span>
                  </div>
                  <div className="certified-id">
                    <span className="key">Certification ID:</span>{" "}
                    <span className="value">{id}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default CertificationSection;
