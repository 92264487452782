import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import { useGetDashboardDataQuery } from "../../features/users/usersApiSlice";
import { NotificationManager } from "react-notifications";
import HomeHeader from "./HomeHeader/HomeHeader";
import CounterOverview from "./CounterOverview/CounterOverview";
import Activities from "./Activities/Activities";
import OrganisationInfo from "./OrganisationInfo/index";
import NewOrgansation from "../../components/NewOrgansation";
import "./styles.scss";

const Home = () => {
  const user = useSelector(selectCurrentUser);
  const { data: dashboardData, error: errorDashboardData, isError: isErrorDashboardData, refetch } =
    useGetDashboardDataQuery();

  const capitalizeFirstLetter = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  useEffect(() => {
    refetch();
    if (errorDashboardData) {
      NotificationManager.error(
        `Failed to load dashboard data: ${
          errorDashboardData?.message || "Unknown error"
        }`,
        "Error"
      );
    }
  }, [isErrorDashboardData, errorDashboardData, refetch]);

  return (
    <section className="layout-inner">
      {user &&
      (user.organisation.name !== "" || user.organisation.name_nn === null) &&
      user.organisation.is_initial_members_added_ind ? (
        <div className="show-organisation-outer">
          <div className="row" style={{ height: "100%" }}>
            <div className="col-8 left-data">
              <HomeHeader />
              <CounterOverview dashboardData={dashboardData} />
              <Activities />
            </div>
            <OrganisationInfo user={user} capitalizeFirstLetter={capitalizeFirstLetter} />
          </div>
        </div>
      ) : (
        <NewOrgansation />
      )}
    </section>
  );
};

export default Home;
