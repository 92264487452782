import Popup from "reactjs-popup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import "./styles/delete_location.scss";

export const DeleteLocationPopup = ({ name, level, show, onSubmit, onClose }) => {
  const user = useSelector(selectCurrentUser);

  const submitHandle = () => {
    onSubmit();
    onClose();
  };


  return (
    <div>
      <Popup
        modal
        className="delete-location-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="delete-location-outer">
          <div className="popup-heading">Delete location</div>
          <div className="popup-content-inner">
            <div className="location-name-outer">
              <div className="name-label">
                Are you sure you want to delete {name} at <span style={{fontWeight: 'bold', color: 'rgb(45 140 241)'}}>{level} Level</span> and all branches in it?
              </div>
            </div>
          </div>
          <div className="popup-content-functionality-btn">
            <button
              className={"submit-btn"}
              onClick={submitHandle}
            >
              Yes
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
