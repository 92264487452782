import React from "react";

const Activities = () => (
  <div className="activities-outer">
    <div className="title">Activities</div>
    <div className="no-activities">No Activities</div>
  </div>
);

export default Activities;
