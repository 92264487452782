import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import MemberSelect from "../../components/MemberSelect/MemberSelect";
import EditPencil from "../../assets/images/add-event/pencil-icon.svg";
import LocationIcon from "../../assets/images/add-event/location-icon.svg";
import DragAndDrop from "../../components/common/DragAndDrop";
import InputField from "../../components/common/InputField";
import { Button } from "../../components/common";
import "../Add Event/addevent.scss";
import {
  useCreateEventMutation,
  useLazyGetMembersListQuery,
} from "../../features/users/usersApiSlice";

const today = new Date();
const todayString = today.toISOString().split("T")[0];
const threeMonthsFromNow = new Date(today.setMonth(today.getMonth() + 3));

const Addevent = () => {
  const { state } = useLocation();
  const [timeError, setTimeError] = useState("");
  const location = useLocation();
  const { itemId, locationName } = location.state || {};
  const [createEvent, { isLoading, error }] = useCreateEventMutation();
  const navigate = useNavigate();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [uploadedBanner, setUploadedBanner] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [currentTime, setCurrentTime] = useState("");
  const [fetchEmployees, { data: employeesRefetchData }] =
    useLazyGetMembersListQuery({ organisation_id: state.organisationId });
  const handleFileErrors = (errors) => {
    setFileErrors(errors);
  };

  const handleCreateEvent = async (values) => {
    console.log('calledhandle')
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description_nn", values.description);

      if (values.banner) {
        formData.append("banner", values.banner);
      } else {
        toast.error("No banner file provided.");
        return;
      }

      if (values.startDate && values.startTime) {
        // Combine date and time manually
        const startDateTime = `${values.startDate}T${values.startTime}:00`;
        formData.append("start_datetime", startDateTime);
      } else {
        console.error("Start date or time is missing");
        return;
      }
      if (values.scheduledDate && values.scheduledTime) {
        // Combine date and time manually
        const scheduledDateTime = `${values.scheduledDate}T${values.scheduledTime}:00`;
        formData.append("schedule_time", scheduledDateTime.toString());
      }

      formData.append("location_fk", values.location || "");
      const members = selectedMembers.map((member) => member.value);
      formData.append("members_list", JSON.stringify(members));
      uploadedFiles.forEach((file) => {
        formData.append("attachment_files", file);
      });
      await createEvent(formData).unwrap();
      formik.resetForm();
      setSelectedMembers([]);
      setUploadedBanner([]);
      setUploadedFiles([]);
      toast.success("Event created successfully!");
    } catch (error) {
      console.log("Error creating event:", error);
      toast.error(error?.data?.message || "Failed to create event. Please try again later.");
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .matches(/^[a-zA-Z0-9 ]+$/, "Title can only contain alphabetic characters, digits, and spaces")
      .min(3, "Title must be at least 3 characters")
      .max(100, "Title cannot exceed 100 characters")
      .test(
        "is-not-just-numbers",
        "Title cannot consist of only numbers",
        function (value) {
          return !/^\d+$/.test(value);
        }
      ),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .test(
        "is-not-just-numbers",
        "Description cannot consist of only numbers",
        function (value) {
          return !/^\d+$/.test(value); 
        }
      ),
      startDate: Yup.date()
      .required("Start date is required")
      .test("start-date-validation", "Start date and time must be in the future", function (value) {
        const { startTime } = this.parent;
        if (!value || !startTime) return true;
    
        const now = new Date();
        const startDateTime = new Date(value);
    
        const [hours, minutes] = startTime.split(":").map(Number);
        startDateTime.setHours(hours, minutes, 0, 0);
    
        return startDateTime >= now;
      }),
    
    startTime: Yup.string()
      .required("Start time is required")
      .test("start-time-validation", "Start time must be in the future", function (value) {
        const { startDate } = this.parent;
        if (!value || !startDate) return true;
    
        const now = new Date();
        const startDateObj = new Date(startDate);
        const [hours, minutes] = value.split(":").map(Number);
    
        startDateObj.setHours(hours, minutes, 0, 0);
    
        return startDateObj >= now;
      }),
    
    endDate: Yup.date()
      .nullable()
      .required("End date is required")
      .test("end-date-validation", "End date and time must be after the start date and time", function (value) {
        const { startDate, startTime, endTime } = this.parent;
    
        if (!value || !startDate || !startTime || !endTime) return true;
    
        // Create start and end DateTime objects
        const startDateTime = new Date(startDate);
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        startDateTime.setHours(startHours, startMinutes, 0, 0);
    
        const endDateTime = new Date(value);
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        endDateTime.setHours(endHours, endMinutes, 0, 0);
    
        const duration = endDateTime - startDateTime;
    
        // Minimum duration: 2 hours
        const minDuration = 2 * 60 * 60 * 1000;
    
        // Maximum duration: 3 months
        const maxDuration = 3 * 30 * 24 * 60 * 60 * 1000;
    
        if (duration < minDuration) {
          return this.createError({
            path: "endDate",
            message: "The event must last at least 2 hours",
          });
        }
    
        if (duration > maxDuration) {
          return this.createError({
            path: "endDate",
            message: "The event cannot last more than 3 months",
          });
        }
    
        return true;
      }),
    
    endTime: Yup.string()
      .required("End time is required")
      .test("end-time-validation", "End time must be after the start time", function (value) {
        const { startDate, startTime, endDate } = this.parent;
    
        if (!value || !startDate || !startTime || !endDate) return true;
    
        // Create start and end DateTime objects
        const startDateTime = new Date(startDate);
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        startDateTime.setHours(startHours, startMinutes, 0, 0);
    
        const endDateTime = new Date(endDate);
        const [endHours, endMinutes] = value.split(":").map(Number);
        endDateTime.setHours(endHours, endMinutes, 0, 0);
    
        return endDateTime > startDateTime;
      })
    ,
      scheduledDate: Yup.date()
      .nullable()
      .test(
        "scheduled-date-required",
        "Both scheduled date and time must be provided if either is specified",
        function (value) {
          const { scheduledTime } = this.parent;
          if (!value && !scheduledTime) return true;
          if ((value && !scheduledTime) || (!value && scheduledTime)) {
            return this.createError({
              path: "scheduledDate",
              message: "Both scheduled date and time are required",
            });
          }
          return true;
        }
      )
      .test(
        "scheduled-datetime-future",
        "Scheduled date and time must not be before the current time",
        function (value) {
          const { scheduledTime } = this.parent;
          if (!value || !scheduledTime) return true;
    
          const now = new Date();
          const scheduledDateTime = new Date(value);
          const [scheduledHours, scheduledMinutes] = scheduledTime.split(":").map(Number);
          scheduledDateTime.setHours(scheduledHours, scheduledMinutes, 0, 0);
    
          return scheduledDateTime >= now;
        }
      )
      .test(
        "scheduled-datetime-validation",
        "Scheduled date and time must not be after the start date and time",
        function (value) {
          const { scheduledTime, startDate, startTime } = this.parent;
          if (!value || !scheduledTime || !startDate || !startTime) return true;
    
          const scheduledDateTime = new Date(value);
          const [scheduledHours, scheduledMinutes] = scheduledTime.split(":").map(Number);
          scheduledDateTime.setHours(scheduledHours, scheduledMinutes, 0, 0);
    
          const startDateTime = new Date(startDate);
          const [startHours, startMinutes] = startTime.split(":").map(Number);
          startDateTime.setHours(startHours, startMinutes, 0, 0);
    
          return scheduledDateTime <= startDateTime;
        }
      ),
    
    scheduledTime: Yup.string()
      .nullable()
      .test(
        "scheduled-time-required",
        "Both scheduled date and time must be provided if either is specified",
        function (value) {
          const { scheduledDate } = this.parent;
          if (!value && !scheduledDate) return true; 
          if ((value && !scheduledDate) || (!value && scheduledDate)) {
            return this.createError({
              path: "scheduledTime",
              message: "Both scheduled date and time are required",
            });
          }
          return true;
        }
      )
      .test(
        "scheduled-time-future",
        "Scheduled date and time must not be before the current time",
        function (value) {
          const { scheduledDate } = this.parent;
          if (!value || !scheduledDate) return true;
    
          const now = new Date();
          const scheduledDateTime = new Date(scheduledDate);
          const [scheduledHours, scheduledMinutes] = value.split(":").map(Number);
          scheduledDateTime.setHours(scheduledHours, scheduledMinutes, 0, 0);
    
          return scheduledDateTime >= now;
        }
      ),
    banner: Yup.mixed().required("Banner is required"),
    attachments: Yup.array().nullable(),
  });




  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      location: itemId,
      scheduledDate: "",
      scheduledTime: "",
      members: [],
      banner: null,
      attachments: [],
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await handleCreateEvent(values);
    },
  });

  console.log(formik.errors, 'errorrs')

  useEffect(() => {
    if (uploadedBanner.length > 0) {
      const file = uploadedBanner[0];
      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFormats.includes(file.type)) {
        formik.setFieldValue("banner", file);
      } else {
        setUploadedBanner([]);
        toast.error(
          "Invalid file format. Only PNG, JPEG, and JPG are allowed."
        );
      }
    }
  }, [uploadedBanner]);

  useEffect(() => {
    const hours = today.getHours().toString().padStart(2, "0");
    const minutes = today.getMinutes().toString().padStart(2, "0");
    setCurrentTime(`${hours}:${minutes}`);
  }, []);

  useEffect(() => {
    const newFileErrors = [];
    const allFiles = [...uploadedBanner, ...uploadedFiles];
    allFiles.forEach((file) => {
      if (file.size > 20 * 1024 * 1024) {
        newFileErrors.push(`${file.name} is larger than 20MB`);
      }
    });
    setFileErrors(newFileErrors);
  }, [uploadedBanner, uploadedFiles]);

  const promiseOptions = (inputValue) => {
    if (inputValue.length < 3) {
      return Promise.resolve([]);
    }
    return fetchEmployees({
      organisation_id: state.organisationId,
      keyword: inputValue,
    })
      .then((response) => {
        const data = response?.data;
        if (data && data.data) {
          return data.data.map((item) => ({
            label: item.name_nn,
            value: item.profile_id,
            profile_image: item.image,
          }));
        }
        return [];
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
        return [];
      });
  };


  const handleCancel = () => {
    navigate("/organisations");
  };

  const getTimeMinValue = () => {
    if (formik.values.startDate === todayString) {
      return currentTime;
    }
    return "00:00";
  };

  const getEndDateMin = (startDate, startTime) => {
    if (!startDate || !startTime) return undefined;
  
    const startDateTime = new Date(startDate);
    const [hours, minutes] = startTime.split(":").map(Number);
    startDateTime.setHours(hours, minutes, 0, 0);
  
    const minEndDate = new Date(startDateTime.getTime() + 2 * 60 * 60 * 1000); 
    return minEndDate.toISOString().split("T")[0]; 
  };
  const getEndDateMax = (startDate) => {
    if (!startDate) return undefined;
  
    const startDateObj = new Date(startDate);
    const maxEndDate = new Date(startDateObj.setMonth(startDateObj.getMonth() + 3));
    return maxEndDate.toISOString().split("T")[0];
  };
  const getEndTimeMin = (startTime) => {
    if (!startTime) return undefined;
  
    const [hours, minutes] = startTime.split(":").map(Number);
    const minEndTime = new Date();
    minEndTime.setHours(hours + 2, minutes, 0, 0); 
    return minEndTime.toISOString().split("T")[1].slice(0, 5); 
  };
      

  return (
    <div className="main-event-part">
      <div className="upper-event-part">
        <div className="event-name">Create Event</div>
        <div className="event-purpose">
          Fill in the Details to Launch Your Upcoming Event
        </div>
      </div>
      <div className="lower-event-part">
        <form onSubmit={formik.handleSubmit}>
          <div className="event-input">
            <label>Title</label>
            <div className="input-with-icon">
              <InputField
                id="title"
                type="text"
                value={formik.values.title}
                placeholder="Enter event title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                icon={<img src={EditPencil} alt="Edit Icon" />}
                className="input-field-add-event"
                inputClassName="input-add-event"
                name="title"
              />
            </div>
            {formik.touched.title && formik.errors.title ? (
              <div className="error-message">{formik.errors.title}</div>
            ) : null}
          </div>

          <div className="event-input">
            <label>Description</label>
            <div className="description-editor">
              <div className="editor-content">
                <InputField
                  id="description"
                  type="textarea"
                  value={formik.values.description}
                  placeholder="Enter event description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="input-field"
                  inputClassName="textarea"
                  name="description"
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="error-message">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="end-start-event">
            <div className="event-input">
              <label>Event Starts</label>
              <div className="input-with-icons">
                <InputField
                  type="date"
                  name="startDate"
                  value={formik.values.startDate}
                  min={todayString}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="date"
                />

                <InputField
                  type="time"
                  name="startTime"
                  min={formik.values.startDate === todayString ? getTimeMinValue() : undefined}
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="time"
                />
              </div>
              {(formik.touched.startTime && formik.errors.startTime) ||
              (formik.touched.startDate && formik.errors.startDate) ? (
              <div className="error-message">
                {formik.touched.startDate && formik.errors.startDate
                  ? formik.errors.startDate
                  : formik.errors.startTime}
              </div>
            ) : null}
            </div>
            

          </div>

          <div className="end-start-event">
            <div className="event-input">
              <label>Event Ends</label>
              <div className="input-with-icons">
                <InputField
                  type="date"
                  name="endDate"
                  value={formik.values.endDate}
                  min={getEndDateMin(formik.values.startDate, formik.values.startTime)}
                  max={getEndDateMax(formik.values.startDate)} 
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="date"
                />
                <InputField
                  type="time"
                  name="endTime"
                  min={formik.values.endDate === formik.values.startDate ? getEndTimeMin(formik.values.startTime) : undefined}
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="time"
                />
              </div>
              {(formik.touched.endDate && formik.errors.endDate) ||
                timeError ||
                (formik.touched.endTime && formik.errors.endTime) ? (
                <div className="error-message">
                  {formik.touched.endDate && formik.errors.endDate
                    ? formik.errors.endDate
                    : timeError
                      ? timeError
                      : formik.errors.endTime}
                </div>
              ) : null}

            </div>
          </div>

          <div className="event-input">
            <label>Location</label>
            <div className="input-with-icon">
              <InputField
                disabled
                type="text"
                name="location"
                value={locationName || "No location selected"}
                readOnly
                icon={<img src={LocationIcon} alt="Location Icon" />}
                className="input-field-add-event"
              />
            </div>
          </div>

          <div className="event-input">
            <MemberSelect
              selectedMembers={selectedMembers}
              setSelectedMembers={(members) => {
                setSelectedMembers(members);
                formik.setFieldValue("members", members);
              }}
              loadOptions={promiseOptions}
            />
          </div>

          <div className="event-input">
            <label>Banner</label>
            <DragAndDrop
              selectedFiles={uploadedBanner}
              setSelectedFiles={setUploadedBanner}
              onError={handleFileErrors}
              maxFileCount={1}
              maxFileSizeMB={5}
            />
            {formik.touched.banner && formik.errors.banner ? (
              <div className="error-message">{formik.errors.banner}</div>
            ) : null}
          </div>

          <div className="event-input">
            <label>Attachments</label>
            <DragAndDrop
              selectedFiles={uploadedFiles}
              setSelectedFiles={setUploadedFiles}
              onError={handleFileErrors}
              maxFileCount={5}
              maxFileSizeMB={20}
            />
          </div>
          <div className="event-input">
            <label>Scheduled Date & Time</label>
            <div className="input-with-icons">
              <InputField
                type="date"
                name="scheduledDate"
                value={formik.values.scheduledDate}
                onChange={(e) => {
                  formik.handleChange(e);
                }
                }
                onBlur={formik.handleBlur}
                min={todayString}
                className="date"
              />
              <InputField
                type="time"
                name="scheduledTime"
                value={formik.values.scheduledTime}
                onChange={formik.handleChange}
                min={getTimeMinValue()}
                onBlur={formik.handleBlur}
                className="time"
              />
            </div>
            {(formik.touched.scheduledDate && formik.errors.scheduledDate) ||
              (formik.touched.scheduledTime && formik.errors.scheduledTime) ||
              timeError ? (
              <div className="error-message">
                {formik.touched.scheduledDate && formik.errors.scheduledDate
                  ? formik.errors.scheduledDate
                  : formik.touched.scheduledTime && formik.errors.scheduledTime
                    ? formik.errors.scheduledTime
                    : timeError}
              </div>
            ) : null}
          </div>
          <div className="event-actions">
            <Button type="button" onClick={handleCancel} className="cancel-btn" buttonName="Cancel" />
            <Button type="submit" loading={formik.isSubmitting} className="create-btn" buttonName="Create" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addevent;
