import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import {
    useCreateAnnouncementMutation
} from "../../features/users/usersApiSlice";
import './Announcement.scss';
import { Button, InputField } from '../../components/common';

const images = {
    pencil: require('../../assets/images/ci_edit-pencil-line-01.png'),
    clarity: require('../../assets/images/clarity_details-line.png'),
    frame: require('../../assets/images/Frame 627337.png'),
    globe: require('../../assets/images/Globe.png'),
    linkIcon: require('../../assets/images/lucide_link.png'),
    frame1: require('../../assets/images/Frame 627336 (1).png'),
    calendar: require('../../assets/images/mdi_calendar.png')
};

const validationSchema = Yup.object({
    title: Yup.string()
        .min(3, 'Title must be at least 3 characters')
        .required('Title is required'),
    description: Yup.string()
        .min(10, 'Description must be at least 10 characters')
        .required('Description is required'),
    date: Yup.date()
        .required('Date is required')
        .test('is-today-or-future', 'Date cannot be in the past', (value) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0); 
            return value && value >= today;
        }),
    startTime: Yup.string()
        .required('Start time is required')
        .test('is-valid-time', 'Start time cannot be in the past', function (value) {
            const selectedDate = this.parent.date; 
            const today = new Date(); 

            if (selectedDate) {
                const selectedDateTime = new Date(selectedDate);
                const [hours, minutes] = value.split(':');
                selectedDateTime.setHours(hours, minutes, 0, 0);

                if (selectedDateTime < today) {
                    return false; 
                }
            }

            return true; 
        }),
    link: Yup.string().url('Enter a valid URL'),
});

const AddAnnouncement = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const currentDate = new Date();


    const formattedDate = currentDate.toISOString().split('T')[0];
    
    // Add 10 minutes to the current time
    const newStartTime = new Date(currentDate.getTime() + 10 * 60000);
    const formattedStartTime = newStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
    const initialValues = useMemo(() => ({
        title: '',
        description: '',
        date: formattedDate,
        startTime: formattedStartTime,
        location: state.name,
        link: '',
    }), [state.name, formattedStartTime]);

    const [createAnnouncementMutation] = useCreateAnnouncementMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const { date, startTime, title, description, link } = values;
            const isoString = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${startTime}:00Z`;

            const data = {
                title,
                content: description,
                scheduled_time: isoString,
                location_fk: state.itemId,
                link
            };

            try {
                const response = await createAnnouncementMutation(data);
                if (response.data) {
                    NotificationManager.success('Announcement Created', 'Announcement created successfully');
                    navigate(-1);
                } else {
                    NotificationManager.error('Annountment is not created', 'Error creating announcement');
                }
            } catch (error) {
                NotificationManager.error('Annountment is not created', 'Error creating announcement');
            }
        },
    });

    const renderError = (field) => (
        formik.touched[field] && formik.errors[field] && (
            <div className="error">{formik.errors[field]}</div>
        )
    );

    return (
        <div className="add-announcement-form">
            <h2>Create Announcement</h2>
            <p>Share Updates Seamlessly with your Team</p>
            <hr />
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <div className="add-images">
                        <img src={images.frame1} alt="Icon" className="input-icon" />
                        <label>What’s your announcement about?</label>
                    </div>
                    <div className='title'>Title</div>
                    <InputField
                            id="title"
                            type="text"
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            placeholder="Enter announcement title"
                            onChange={formik.handleChange}
                            icon={<img src={images.pencil} alt="Icon" className="input-icon" />}
                            className="input-wrapper"
                        />
                    {renderError('title')}
                    <div className='description'>Description</div>
                    <InputField
                        id="description"
                        type="textarea"
                        value={formik.values.description}
                        placeholder="Enter announcement details here..."
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        icon={<img src={images.clarity} alt="Icon" className="input-icon" />}
                        className="input-wrapper input-wrapper-description"
                        name="description"
                        inputClassName="description-input"
                        maxLength="500"
                        />

                    {renderError('description')}
                </div>

                <div className="form-group">
                    <div className="add-images">
                        <img src={images.frame} alt="Icon" className="input-icon" />
                        <label>When and where will it take place?</label>
                    </div>

                    <div className="date-time-wrapper">
                        <div className="custom-date-input">
                            <label className="date">Date</label>
                            <InputField
                            id="date"
                            type="date" // Use the native date input type
                            value={formik.values.date}
                            placeholder="Select a date"
                            onChange={formik.handleChange}
                            onBlur={() => formik.setFieldTouched('date', true)}
                            className="date-picker-wrapper"
                            inputClassName="date-picker"
                            // icon={<img src={images.calendar} alt="calendar-icon" className="calendar-icon" />}
                            />
                        </div>
                        <div className="custom-date-input">
                        <label className="date">At</label>
                        <InputField
                            id="startTime"
                            type="time"
                            value={formik.values.startTime}
                            placeholder="Select a time"
                            onChange={formik.handleChange}
                            onBlur={() => formik.setFieldTouched('startTime', true)}
                            className="time-input-wrapper"
                            inputClassName="time-picker"
                        />
                        </div>
                        </div>

                    {renderError('date')}
                    {renderError('startTime')}
                </div>
                <div className="form-group">
                <div className='location'>Location</div>
                <InputField
                    id="location"
                    type="text"
                    value={formik.values.location}
                    placeholder="Location"
                    icon={<img src={images.globe} alt="Icon" className="input-icon" />}
                    className="input-wrapper"
                    disabled
                    />

                {renderError('location')}
                <div className='link'>Link <span style={{ color: "grey" }}>(Optional)</span></div>
                <InputField
                    id="link"
                    type="url"
                    value={formik.values.link}
                    placeholder="Link to the meeting"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    icon={<img src={images.linkIcon} alt="Icon" className="input-icon" />}
                    className="link-input-wrapper"
                    />

                {renderError('link')}
                </div>

                <div className="form-actions">
  <Button
    type="button"
    className="cancel-btn"
    onClick={() => navigate(-1)}
  >
    Cancel
  </Button>
  <Button
    type="submit"
    className="create-btn"
    loading={formik.isSubmitting} // Pass a loading indicator during submission
    disabled={
      !formik.values.title ||
      !formik.values.description ||
      Object.keys(formik.errors).length > 0
    }
  >
    Create
  </Button>
</div>

            </form>
        </div>
    );
};

export default AddAnnouncement;
