import React, { useState, useEffect } from "react";
import { Outlet,useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { store } from "../../../app/api/store";
import { selectCurrentUser, logOut } from "../../../app/hooks/useAuth";
import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import avatar3 from '../../../assets/images/avatar3.png'
import "./styles.scss";

const Layout2 = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { data: user_data } = useGetUsersQuery();

  if (user_data?.data.is_staff === false) {
    store.dispatch(logOut());
  }

  const LogoutHandle = () => {
    store.dispatch(logOut());
  };

  const handleAccount=()=>{
    navigate("/account")
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setToggleSidebar(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="App">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="layout2-inner">
        {user &&
          (user.organisation.name_nn !== "" || user?.organisation.name_nn === null) &&
          user?.organisation.is_initial_members_added_ind && (
            <div
            className="layout2-inner-sidebar"
            style={toggleSidebar ? { width: "250px" } : {}}
          >
            <Sidebar
             className="lala"
              toggleSidebar={toggleSidebar}
              setToggleSidebar={setToggleSidebar}
              user={user}
            />
            </div>
          )}
        <div className="layout2-inner-main">
          <CustomNavbar
            handleToggleSidebar={() => setToggleSidebar(!toggleSidebar)}
            avatar={avatar3}
            LogoutHandle={LogoutHandle}
            handleAccount={handleAccount}
          />
          <div
            style={{
              marginTop: "70px",
              height: "calc(100% - 60px)",
              width: "100%",
              flexGrow: "1",
              overflow: "scroll",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout2;
