import React from "react";

const OverviewCard = ({ heading, count, subHeading, className }) => (
  <div className={`col-4 ${className}`}>
    <div className="heading">{heading}</div>
    <div className="count">{count}</div>
    <div className="sub-heading">{subHeading}</div>
  </div>
);

export default OverviewCard;
