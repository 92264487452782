import React from "react";
import GlobeIcon from "../../../assets/images/member-details/websiteicon.svg";

const PersonalWebsite = () => {
  const website = "www.sarahbrown.dev";

  return (
        <div className="right-middle-div">
          <div className="heading-personalwebsite">PERSONAL WEBSITE</div>
          <div className="website-data">
            <div className="history">
              <div className="left-div-web">
                <img src={GlobeIcon} alt="Website Icon" />
              </div>
              <div className="right-div-web">
                <div className="website-url">{website}</div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default PersonalWebsite;
