import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useInviteMemberToLocationMutation } from '../../features/users/usersApiSlice';
import { MemberInvitedPopup } from '../../components/popups/MemberInvited';
import { NotificationManager } from 'react-notifications';
import InputField from '../../components/common/InputField';
import './inviteMember.scss';
import { FaUser } from 'react-icons/fa'; 

const InviteMemberForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMemberInvitedPopup, setShowMemberInvitedPopup] = useState(false);
  const { organisationId, itemId, groupId } = location.state || {};
  const [shareLink, setShareLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adminAccess, setAdminAccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const [inviteMemberToLocation] = useInviteMemberToLocationMutation();

  const handleInvite = async (e) => {
    e.preventDefault();

    if (!isFormValid) return;

    const data = {
      is_admin_access_ind: adminAccess,
      location: itemId,
      organisation: organisationId,
    };
    if (email) {
      data.email = email;
    } else {
      data.phone = phone;
    }

    try {
      const response = await inviteMemberToLocation(data).unwrap();

      if (response.data) {
        setShareLink(response.data.link);
        setShowMemberInvitedPopup(true);
      }
    } catch (error) {
      NotificationManager.error(error?.data?.message || "An error occurred. Please try again.");
      setShowMemberInvitedPopup(true);
    }
  };

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!email && !phone) {
      setIsFormValid(false);
      return;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (phone && !/^\+91[6-9]\d{9}$/i.test(phone)) {
      newErrors.phone = 'Phone number must start with +91 and have exactly 10 digits.';
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [email, phone]);
  
  useEffect(() => {
    validateForm();
  }, [email, phone, validateForm]);

  useEffect(() => {
    if (!organisationId || !itemId || !groupId) {
      navigate(-1);
    }
  }, [organisationId, itemId, groupId, navigate]);

  const onMemberInvitedPopupClose = () => {
    setShowMemberInvitedPopup(false);
    setErrorMessage(''); 
    setShareLink('');
    navigate(-1);
  };


  return (
    <div className="invite-member-form">
      <h2>Invite Member to Chat Group</h2>
      <p>Connect and Collaborate Instantly</p>

      <h4 className="personal-info-header">
        <FaUser style={{ color: '#32A0EF' }} /> Personal Information
      </h4>
      <Form onSubmit={handleInvite} className="form-invite-member">
        <Row className="row-info-location">
          <Col className="col-margin">
          <InputField
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email Id"
              className="form-control"
              inputClassName="input-field-control"
            /> 
          </Col>
          <Col className="col-margin">
          <InputField
              id="phone"
              label="Phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter phone number (+91)"
              className="form-control"
              inputClassName="input-field-control"
            />
          </Col>
        </Row>

        {errors.general && <div className="error-text">{errors.general}</div>}

        <Form.Group controlId="adminAccess" className="mt-3">
          <Form.Check
            type="checkbox"
            label="Grant Admin Access"
            checked={adminAccess}
            onChange={(e) => setAdminAccess(e.target.checked)}
          />
        </Form.Group>

        <div className="button-group mt-4">
          <Button variant="secondary" type="button" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="ml-2"
            disabled={!isFormValid}
          >
            Invite
          </Button>
        </div>
      </Form>

      <MemberInvitedPopup
        link={shareLink}
        errorMessage={errorMessage}
        show={showMemberInvitedPopup}
        onClose={onMemberInvitedPopupClose}
      />
    </div>
  );
};

export default InviteMemberForm;
