import React from "react";
import Avatar from "../../assets/images/avatar.png";
import "./styles/modalMemberDetails.scss";

const ModalMemberDetails = ({ selectedMember, onClose }) => {

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="heading">User Details</div>
        <div className="member-card">
          <div className="member-profile">
            <img
              src={selectedMember.image || Avatar}
              alt={selectedMember.name}
              className="profile-picture"
            />
            <div className="member-info">
              <h3>{capitalizeFirstLetter(selectedMember.name_nn) || "Name Not Available"}</h3>
              <p>{selectedMember.designation || "Designation Not Available"}</p>
              <p>{selectedMember.memberID || "Member ID Not Available"}</p>
            </div>
          </div>
          <div className="member-details">
            <div className="row">
              <div className="pair-key-value">
                <div className="key-value">
                  <span className="key">Email ID:</span>{" "}
                  <span className="value">
                    {selectedMember.email || "Email Not Available"}
                  </span>
                </div>
                <div className="key-value">
                  <span className="key">Phone Number:</span>{" "}
                  <span className="value">
                    {selectedMember.phone_number || "Phone Not Available"}
                  </span>
                </div>
              </div>
              <div className="pair-key-value">
                <div className="key-value">
                  <span className="key">Designation:</span>{" "}
                  <span className="value">
                    {selectedMember.designation || "Designation Not Available"}
                  </span>
                </div>
                <div className="key-value">
                  <span className="key">Role:</span>{" "}
                  <span className="value">
                    {selectedMember.role || "Role Not Available"}
                  </span>
                </div>
              </div>
              <div className="pair-key-value">
                <div className="key-value">
                  <span className="key">Department:</span>{" "}
                  <span className="value">
                    {selectedMember.department || "Department Not Available"}
                  </span>
                </div>
                <div className="key-value">
                  <span className="key">Sub-Department:</span>{" "}
                  <span className="value">
                    {selectedMember.sub_department ||
                      "Sub-Department Not Available"}
                  </span>
                </div>
              </div>
              <div className="pair-key-value">
                <div className="key-value">
                  <span className="key">Experience:</span>{" "}
                  <span className="value">
                    {selectedMember.experience || "Experience Not Available"}{" "}
                    yrs
                  </span>
                </div>
                <div className="key-value">
                  <span className="key">Website:</span>{" "}
                  <span className="value">
                    {selectedMember.website || "Website Not Available"}
                  </span>
                </div>
              </div>
              <div className="pair-key-value">
                <div className="key-value">
                  <span className="key">Address:</span>{" "}
                  <span className="value">
                    {selectedMember.address || "Address Not Available"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalMemberDetails;
