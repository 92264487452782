import { Link } from "react-router-dom"
import { NotificationManager } from "react-notifications";
import { useGetOrganisationMembersQuery } from "../../features/users/usersApiSlice";
import { useGetChannelsQuery, useGetSubscribedChannelsQuery, useCreateChannelsMutation } from "../../features/channels/channelsApiSlice";
import { React, useState, useEffect } from "react";
import { setChannels } from "../../features/channels/channelSlice";
import { AddChannelPopup } from "../../components/popups/AddChannelPopup";
import { ManageChannelPopup } from "../../components/popups/ManageChannelPopup";
import { store } from "../../app/api/store";

import "./styles.scss";

const ManageChannel = () => {
  const [createChannel] = useCreateChannelsMutation();
  const [trackDirectory, setTrackDirectory] = useState([
    { parent_id: null, name: "Home" },
  ]);
  const [channelName, setChannelName] = useState("")
  const [channelId, setChannelId] = useState("")
  const [showAddChannelPopup, setShowAddChannelPopup] = useState(false)
  const [showManageChannelPopup, setShowManageChannelPopup] = useState(false)
  const [parentId, setParentId] = useState(null);
  const {
    data: channels_data,
    refetch,
  } = useGetChannelsQuery({ parent_id: parentId });

  const {
    refetch: refetch_subscribed_channels_data,
  } = useGetSubscribedChannelsQuery();
  if (channels_data && channels_data.data) {
    store.dispatch(setChannels(channels_data.data));
  }

  const [groupMembers, setGroupMembers] = useState([])

  const getDirectoryName = () => {
    const count = trackDirectory.length - 1
    switch (count) {
      case 0:
        return "Global/"
      case 1:
        return "Global/Continent/"
      case 2:
        return "Global/Continent/Country/"
      case 3:
        return "Global/Continent/Country/State/"
      case 4:
        return "Global/Continent/Country/State/City/"
      case 5:
        return "Global/Continent/Country/State/City/Local/ #Last Level"
      default:
    }

  }
  const getLevelName = () => {
    const count = trackDirectory.length - 1
    switch (count) {
      case 0:
        return "Global"
      case 1:
        return "Continent"
      case 2:
        return "Country"
      case 3:
        return "State"
      case 4:
        return "City"
      case 5:
        return "Local"
      default:
        return null;
    }
  }

  const {
    data: members_data,
    refetch: memberRefetch,
  } = useGetOrganisationMembersQuery();


  useEffect(() => {
    memberRefetch();
    refetch();
  }, [memberRefetch, refetch]);

  const submitAddNewChannelHandler = async (data) => {
    try {
      data = {
        "name": data.name,
        "parent": parentId,
        "level": trackDirectory.length - 1,
        "participants": data.participants,
      };

      const response = await createChannel(data);
      refetch();
      refetch_subscribed_channels_data();
    } catch (error) {
      NotificationManager.error('Failed to create channel.', 'Error');
    }
  };

  const submitManageChannelHandler = (data) => {
}

const refreshManageChannelHandler = async (data) => {
  const new_data = await refetch();
  new_data.data.data.forEach((item)=> {
    if (item.id === data.group_id) {
      setGroupMembers(item.participants)
    }
  })
}

  const onAddChannelPopupClose = (data) => {
    setShowAddChannelPopup(false)
  }
  const onManageChannelPopupClose = (data) => {
    setShowManageChannelPopup(false)
  }

  const handleClick = (e, channel) => {
    switch (e.detail) {
      case 2:
        const count = trackDirectory.length
        if (count < 6) {
          setParentId(channel.id);
          refetch();
          setTrackDirectory([
            ...trackDirectory,
            { parent_id: channel.id, name: channel.name },
          ]);
        }
        break;
      default:
        break;
    }
  };

  const backHandle = () => {
    const tempDirectoryList =[...trackDirectory]; 
    tempDirectoryList.pop()
    setTrackDirectory(tempDirectoryList)
    setParentId(tempDirectoryList[tempDirectoryList.length - 1].parent_id);
    refetch();

  }



  return (
    <section className="layout-inner">
      <div className="channel-member-container-outer">
        <h1 className="page-heading">Groups</h1>
        <div className="functionality-outer">
          <div className="directory-outer">
            <button className={trackDirectory.length > 1 ? "back-directory" : "back-directory back-directory-disabled"} onClick={() => { backHandle() }}>&lt; Back</button>
            <div className="track-directory-list">{getDirectoryName()}</div>
          </div>
          <button className="add-channel-btn" onClick={() => { setShowAddChannelPopup(true) }}>Add Channel</button>

        </div>
        {channels_data && (
          <div className="channel-list-outer">
            <div className="row channel-list-heading-outer">
              <div className="col-6 channel-list-heading">Name</div>
              <div className="col-3 channel-list-heading">User Count</div>
              <div className="col-3 channel-list-heading"></div>
            </div>
            {channels_data.data.map((channel, index) => (
              <div
                key={index}
                className="row channel-list-item-outer"
                onClick={(event) => {
                  handleClick(event, channel);
                }}
              >
                <div className="col-6 channel-list-item">{channel.name}</div>
                <div className="col-3 channel-list-item">
                  {channel.member_count}
                </div>
                <div className="col-3 channel-list-item channel-list-item-manage">
                  <button className="manage-channel-btn" onClick={() => {
                    setGroupMembers(channel.participants)
                    setChannelName(channel.name)
                    setChannelId(channel.id)
                    setShowManageChannelPopup(true)
                  }}>Manage</button>
                  <Link to={`/channel/${channel.id}/`}><button className="manage-channel-btn" style={{ marginLeft: "10px", background: "rgb(36 100 170)" }}>Watch</button></Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <AddChannelPopup
        data={members_data ? members_data.members : []}
        level={getLevelName()}
        show={showAddChannelPopup}
        onSubmit={submitAddNewChannelHandler}
        onClose={
          onAddChannelPopupClose
        }
      />
      <ManageChannelPopup
        name={channelName}
        id={channelId}
        data={groupMembers}
        level={getLevelName()}
        show={showManageChannelPopup}
        onSubmit={submitManageChannelHandler}
        onRefresh={refreshManageChannelHandler}
        onClose={
          onManageChannelPopupClose
        }
      />
    </section>
  );
};

export default ManageChannel;
