import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";

const HamburgerButton = ({ handleClick }) => (
  <button className="left-hamburger-icon" onClick={handleClick}>
    <RxHamburgerMenu />
  </button>
);

export default HamburgerButton;
