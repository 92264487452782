import { createSlice } from "@reduxjs/toolkit"

const channelSlice = createSlice({
    name: 'channel',
    initialState : {
        messages: [],
        channels: []
      },
    reducers: {

        addMessage: (state, action) => {
            state.messages = [...state.messages, action.payload]
          },
          
          setMessages: (state, action) => {
            state.messages = action.payload.reverse()
          },
          setChannels:(state, action) => {
            state.channels = action.payload
          }
    },
})

export const { addMessage, setMessages, setChats, setChannels } = channelSlice.actions

export default channelSlice.reducer

export const selectMessages = (state) => state.channel.messages
export const selectChannels = (state) => state.channel.channels