import { React, useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { AddDocumentPopup } from "../../components/popups/AddDocumentPopup";
import {
  useGetUserAccountQuery,
  useGetMyDocumentsQuery,
  useDeleteAccountMutation,
  useDeleteDocumentMutation,
  useUpdateUserProfileMutation,
} from "../../features/users/usersApiSlice";
import ProfileImage from "../../assets/images/ant-design_profile-outlined.png";
import SettingImage from "../../assets/images/solar_settings-linear.png";
import Danger from "../../assets/images/Vector (5).png";
import Frame from "../../assets/images/Frame 2335.png";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import "./styles.scss";

const Account = () => {
  const [profileData, setProfileData] = useState({});
  const { refetch } = useGetUserAccountQuery();
  const { refetch: documents_refetch } = useGetMyDocumentsQuery();
  const [editProfileData, setEditProfileData] = useState({});
  const [deactivating, setDeactivating] = useState(false);
  const [key, setKey] = useState("profile");
  const [showAddDocumentPopup, setShowAddDocumentPopup] = useState(false);
  const onAddDocumentPopupClose = () => {
    setShowAddDocumentPopup(false);
  };
  const submitAddNewDocumentHandler = (data) => {
    documents_refetch();
  };
  const [deleteDocument, { isLoading: deleteDocumentIsLoading }] =
    useDeleteDocumentMutation();
  const [deleteAccount, { isLoading: deleteAccountIsLoading }] =
    useDeleteAccountMutation();
  const [updateUserProfile, { isLoading: updateUserProfileIsLoading }] =
    useUpdateUserProfileMutation();
  const toastId = useRef(null);
  const DeleteAccountHandler = async () => {
    if (deactivating) return;
    setDeactivating(true);
    try {
      const response = await deleteAccount();
      if (response.data && response.errors === undefined) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success("Account deactivated successfully");
        }
      } else {
        throw new Error("Failed to delete account");
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Failed to delete account. Please try again"
        );
      }
    } finally {
      setDeactivating(false);
    }
  };

  const DeleteDocumentHandler = async (id) => {
    const response = await deleteDocument(id);
    if (response.data && response.errors === undefined) {
      documents_refetch();
    }
  };

  useEffect(() => {
    if (profileData) {
      setEditProfileData(profileData);
    }
  }, [profileData]);

  const onLoadTrigger = async () => {
    const abc = await refetch();
    setProfileData(abc.data.data);
    await documents_refetch();
  };

  useEffect(() => {
    onLoadTrigger();
  },[]);

  useEffect(() => {
    if (profileData) {
      setEditProfileData(profileData);
    }
  }, [profileData]);
  

  const updateProfileHandler = async () => {
    try {
      const response = await updateUserProfile(editProfileData);
      if (response.data && response.errors === undefined) {
        refetch();
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  const cancelUpdateHandler = () => {
    setEditProfileData(profileData);
  };

  return (
    <section className="layout-inner-account">
      {profileData && (
        <div className="accounts-container-outer">
          <div className="accounts-heading">Accounts</div>
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="accounts-tabs"
            fill
          >
            <Tab
              eventKey="profile"
              title={
                <span>
                  <img
                    src={ProfileImage}
                    alt="Profile"
                    className="tab-icon"
                    style={{ marginRight: "8px" }}
                  />
                  Profile
                </span>
              }
            >
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="main-outer">
                    <div className="user-image-outer">
                      <img className="user-image" alt="userphoto" src={Frame} />
                    </div>
                    <div className="details-outer">
                      <div className="row label-input-group">
                        <div className="col-4 label">First Name</div>
                        <div className="col-8 input">
                          <input value={profileData.first_name} disabled />
                        </div>
                      </div>

                      <div className="row label-input-group">
                        <div className="col-4 label">Last Name</div>
                        <div className="col-8 input">
                          <input value={profileData.last_name} disabled />
                        </div>
                      </div>
                      <div className="row label-input-group">
                        <div className="col-4 label">Date Of Birth</div>
                        <div className="col-8 input">
                          <input value={profileData?.date_of_birth} disabled />
                        </div>
                      </div>
                      <div className="row label-input-group">
                        <div className="col-4 label">Gender</div>
                        <div className="col-8 input">
                          <input
                            value={profileData?.Basic_User_Info?.gender}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row label-input-group">
                        <div className="col-4 label">Languages</div>
                        <div className="col-8 input">
                          <input
                            value={
                              profileData?.Additional_Profile_Info?.languages
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row label-input-group">
                        <div className="col-4 label">Bio </div>
                        <div className="col-8 input">
                          <input
                            value={profileData?.Basic_User_Info?.bio}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <div className="contact-info-outer">
                    <div className="heading-container">
                      <div className="heading">Contact Information</div>
                    </div>
                    <div className="contact-info-container">
                      <div className="contact-info-item">
                        <span className="label">Email ID</span>
                        <span className="value">{profileData.email}</span>
                      </div>
                      <div className="contact-info-item">
                        <span className="label">Personal Website</span>
                        <span className="value">
                          {
                            profileData?.Additional_Profile_Info
                              ?.personal_website
                          }
                        </span>
                      </div>
                      <div className="contact-info-item">
                        <span className="label">Mobile Number</span>
                        <span className="value">
                          {
                            profileData?.Additional_Profile_Info
                              ?.contact_information
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="organisation-info-outer">
                    <div className="heading-container">
                      <div className="heading">Organisation Information </div>
                    </div>
                    <div className="organisation-info-container">
                      <div className="organisation-info-box">
                        <div className="row">
                          <div className="col-left">
                            <div className="designation-section">
                              <div className="designation-heading">
                                Designation
                              </div>
                              <p className="designation-title">
                                {
                                  profileData?.Additional_Profile_Info
                                    ?.designation
                                }
                              </p>
                            </div>
                            <div className="experience-section">
                              <div className="experience-heading">
                                Experience
                              </div>
                              <div className="experience-details">
                                <div className="experience-item">
                                  <div className="bullet-circle"></div>
                                  <div className="experience-info">
                                    <div className="position">
                                      Lead Designer TechWave Studios{" "}
                                    </div>
                                    <div>June 2019 - Present</div>
                                    <div>San Francisco, CA</div>
                                  </div>
                                  <div className="line-between"></div>
                                </div>
                                <div className="experience-item">
                                  <div className="bullet-circle"></div>
                                  <div className="experience-info">
                                    <div className="position">
                                      UX/UI Designer
                                    </div>
                                    <div>BrightDigital</div>
                                    <div>September 2018 - June 2019</div>
                                    <div>New York, NY</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-right">
                            <h3>Awards</h3>
                            <ul className="awards-list">
                              <li>Best Designer Award 2020</li>
                              <li>Top Performer of the Year 2021</li>
                            </ul>
                            <h3>Conference & Events</h3>
                            <ul className="conference-list">
                              <li>UI/UX Design Conference 2022</li>
                              <li>Creative Minds Meetup 2023</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="settings"
              title={
                <span>
                  <img
                    src={SettingImage}
                    alt="Settings"
                    className="tab-icon"
                    style={{ marginRight: "8px" }}
                  />
                  Settings
                </span>
              }
            >
              <div className="settings-outer">
                <div className="delete-account-box">
                  <div className="delete-account-heading-box">
                    <div className="delete-account-heading">Delete Account</div>
                    <img
                      src={Danger}
                      alt="Danger"
                      className="delete-account-danger-image"
                    />
                  </div>
                  <div className="delete-account-content">
                    <p className="delete-account-warning">
                      Would you like to delete your account? Deleting your
                      account is permanent and cannot be undone.
                    </p>
                    <p>Deleting your account will do the following:</p>
                    <ul className="delete-account-list">
                      <li>Log out from all devices.</li>
                      <li>Delete your account information.</li>
                    </ul>
                    <button
                      className="delete-account-btn"
                      onClick={DeleteAccountHandler}
                    >
                      Delete My Account
                    </button>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          <AddDocumentPopup
            show={showAddDocumentPopup}
            onSubmit={submitAddNewDocumentHandler}
            onClose={onAddDocumentPopupClose}
          />
          <Tooltip id="user-verified" />
          <Tooltip id="organisation-verified" />
          <Tooltip id="antro-verified" />
        </div>
      )}
    </section>
  );
};

export default Account;
