import React from "react";
import { TailSpin } from "react-loader-spinner";
import './styles.scss';

export const Button = ({ type, loading, children, onClick, className, buttonName }) => {
  return (
    <button type={type} onClick={onClick} className={`button ${className}`} disabled={loading}>
      {loading && (
        <span style={{ marginRight: "10px" }}>
          <TailSpin
            visible={true}
            height="30"
            width="30"
            color="#03a9f4"
            ariaLabel="loading"
          />
        </span>
      )}
     {buttonName || children}
    </button>
  );
};
export default Button;