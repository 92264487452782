import React from "react";
import { Sidebar as ProSidebar, Menu } from "react-pro-sidebar";
import SidebarLink from "../SidebarLink/SidebarLink";
import { FaHome, FaMapMarkedAlt } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { BiSitemap } from "react-icons/bi";
import "./Sidebar.scss";
import { Link } from "react-router-dom";

const Sidebar = ({ toggleSidebar, setToggleSidebar, user, sidebarRef }) => {
  const handleMenuItemClick = () => {
    if (window.innerWidth <= 1200) {
      setToggleSidebar(false);
    }
  };

  return (
    <div
      className="layout2-inner-sidebar"
      style={toggleSidebar ? { width: "250px" } : {}}
      ref={sidebarRef}
    >
      <ProSidebar>
        <Menu>
          {user?.organisation && user?.organisation.name_nn ? (
            <>
              <div className="sidebar-header">
                <Link to="/home">
                  <img
                    src={user?.organisation.logo}
                    alt="Organisation Logo"
                    className="sidebar-logo"
                  />
                </Link>
              </div>
              <hr />
              <div className="sidebar-links">
                <SidebarLink
                  to="/home"
                  icon={FaHome}
                  label="Home"
                  handleClick={handleMenuItemClick}
                />
                <SidebarLink
                  to="/members"
                  icon={BsPeopleFill}
                  label="Members"
                  handleClick={handleMenuItemClick}
                />
                <SidebarLink
                  to="/map"
                  icon={FaMapMarkedAlt}
                  label="Map"
                  handleClick={handleMenuItemClick}
                />
                <SidebarLink
                  to="/calendar"
                  icon={SlCalender}
                  label="Calendar"
                  handleClick={handleMenuItemClick}
                />
                <SidebarLink
                  to="/organisations"
                  icon={BiSitemap}
                  label="Organisations"
                  handleClick={handleMenuItemClick}
                />
              </div>
            </>
          ) : (
            <div>No Organisation</div>
          )}
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
