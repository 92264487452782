import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import { Table } from "react-bootstrap";
import { useGetFailedUploadMembersQuery } from "../../features/users/usersApiSlice";
import { formatDate } from "../../app/utils/utils";
import "./styles/failed_upload.scss";

export const FailedUploadPopup = ({ show, onClose }) => {
  const {
    data: memberData,
    isLoading: isLoadingLocationData,
    isSuccess: isSuccessLocationData,
    isError: isErrorLocationData,
    error: errorLocationData,
    refetch,
  } = useGetFailedUploadMembersQuery();

  const tableStyle = {
   
    borderRadius: "8px",
    
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
  
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };

  useEffect(()=> {
    if (show) {
      refetch()
    }
  },
  [show])

  return (
    <div>
      <Popup
        modal
        className="failed-upload-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="failed-upload-outer">
          <div className="popup-heading">Failed Uploads</div>
          <div className="popup-content-inner">
          <Table style={tableStyle} className="failed-popup">
            <thead style={tableHeaderStyle}>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Upload Time</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {memberData && memberData.data.map((candidate, index) => (
                <tr key={index} style={rowStyle}>
                  <td style={cellStyle}>{candidate.first_name}</td>
                  <td style={cellStyle}>{candidate.last_name}</td>
                  <td style={cellStyle}>{candidate.email}</td>
                  <td style={cellStyle}>{formatDate(candidate.created_at)}</td>
                  <td style={cellStyle}>{candidate.failed_reason}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </div>
      </Popup>
    </div>
  );
};
