import React, { useState, useEffect } from 'react';
import './styles.scss';
import { RxCaretUp, RxCaretDown } from "react-icons/rx";

const LocationNode = ({
  location,
  SubmitAddLocationHandler,
  SubmitDeleteLocationHandler,
  SubmitAddBranchHandler,
  SubmitBranchDetailHandler,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [sublocations, setSublocations] = useState(location.sublocations || []);
  const [branches, setBranches] = useState(location.branches || []);

  useEffect(() => {
    setSublocations(location.sublocations || []);
    setBranches(location.branches || []);
  }, [location]);

  const hasSublocations = sublocations.length > 0;
  const hasBranches = branches.length > 0;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleAddSublocation = (level, id) => {
    SubmitAddLocationHandler(parseInt(level), id);
  };

  const handleRemoveSublocation = (id, level, name) => {
    SubmitDeleteLocationHandler(id, parseInt(level), name);
  };

  const handleAddBranch = (id, name) => {
    SubmitAddBranchHandler(id, name);
  };

 

  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Global";
      case 1:
        return "Continent";
      case 2:
        return "Country";
      case 3:
        return "State";
      case 4:
        return "City";
      case 5:
        return "Local";
      default:
        return "Location";
    }
  };

  return (
    <div className="location-node">
      <div className="location-header">
        {hasSublocations ? (
          <button className="toggle-button" onClick={toggleExpanded}>
            {expanded ? <RxCaretDown /> : <RxCaretUp />}
          </button>
        ) : (
          <span className="spacer"></span>
        )}
        <span className="location-name">{location.name_nn}</span>
        <div className="location-actions">
          {parseInt(location.level) < 5 && (
            <button
              className="add-button"
              onClick={() => {
                handleAddSublocation(location.level, location.location_id);
              }}
            >
              Add {getLevelName(parseInt(location.level) + 1)}
            </button>
          )}
          {parseInt(location.level) === 5 && (
            <button
              className="add-button"
              onClick={() => {
                handleAddBranch(location.location_id, location.name_nn);
              }}
            >
              Add Branch
            </button>
          )}
          <button
            className="delete-button"
            onClick={() => {
              handleRemoveSublocation(
                location.location_id,
                location.level,
                location.name_nn
              );
            }}
          >
            X
          </button>
        </div>
      </div>
      {expanded && hasSublocations && (
        <div className="sublocations">
          {sublocations.map((subLoc) => (
            <LocationNode
              key={subLoc.location_id}
              location={subLoc}
              SubmitAddLocationHandler={SubmitAddLocationHandler}
              SubmitDeleteLocationHandler={SubmitDeleteLocationHandler}
              SubmitAddBranchHandler={SubmitAddBranchHandler}
              SubmitBranchDetailHandler={SubmitBranchDetailHandler}
            />
          ))}
        </div>
      )}
      {hasBranches && (
        <div className="branches row">
          {branches.map((branch) => (
            <div
              key={branch.branch_id}
              className="branch col-10 col-sm-8"
              onClick={() => {
                SubmitBranchDetailHandler(branch);
              }}
            >
              <span className="branch-name">{branch.branch_name_nn}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationNode;
