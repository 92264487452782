import { ToastContainer } from 'react-toastify';
import React from "react";
import Layout1 from "./components/layouts/layout1/layout1";
import Layout2 from "./components/layouts/layout2/layout2";
import Landing from "./pages/landing";
import Home from "./pages/home";
import Members from "./pages/members";
import Account from "./pages/account";
import ManageChannel from "./pages/manage_channels";
import Map from "./pages/map";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import RequireAuth from "./features/auth/RequireAuth";
import Login from "./pages/login";
import Channel from "./pages/channel/channel";
import "reactjs-popup/dist/index.css";
import "react-tooltip/dist/react-tooltip.css";
import CalendarView from "./pages/calendar";
import Attendance from "./pages/attendance";
import AttendanceHistory from "./pages/attendance_history";
import MemberDetails from "./pages/member_details";
import Organisations from "./pages/organisations";
import SiteMap from "./pages/sitemap";
import InviteMemberForm from "./pages/InviteMember";
import OrganisationChat from "./pages/organisation_message";
import AddAnnouncement from "./pages/AddAnnouncement";
import Addevent from "./pages/Add Event/addevent";

function App() {
  return (
    <>
      {/* Toast notifications container */}
      <ToastContainer />
    <Routes>
      <Route path="/">
        <Route path="/" element={<Layout1 />}>
          <Route path="/" element={<Landing />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout2 />}>
          <Route element={<RequireAuth />}>
            <Route path="home" element={<Home />} />
            <Route path="members" element={<Members />} />
            <Route path="member/:userId/" element={<MemberDetails />} />
            <Route path="/account" element={<Account />} />
            <Route path="/map" element={<Map />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route
              path="/attendance-history/:id/"
              element={<AttendanceHistory />}
            />
            <Route path="/calendar" element={<CalendarView />} />
            <Route path="/manage_channels" element={<ManageChannel />} />
            <Route path="/channel/:channelId/" element={<Channel />} />
            <Route path="/organisations" element={<Organisations />} />
            <Route path="/sitemap/:organisationId" element={<SiteMap />} />
            <Route path="/invite-member" element={<InviteMemberForm />} />
            <Route path="/organisation_chat" element={<OrganisationChat />} />
            <Route path="/add_announcement" element={<AddAnnouncement />} />
            <Route path="/addevent" element={<Addevent />} />
          </Route>
        </Route>
      </Route>
    </Routes>
     </>
  );
}

export default App;
