import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useGetPluginsQuery, useUpdatePluginMutation } from "../../features/users/usersApiSlice";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import "./styles/plugins_popup.scss";

export const PluginsPopup = ({ show, onClose }) => {
  const [checked, setChecked] = useState(false)
  const {
    data: pluginData,
    refetch,
  } = useGetPluginsQuery();

  const [
    updatePlugin
   ] = useUpdatePluginMutation();

  const updatePluginHandler = async (id) => {
    const data = {
      id
    }
    setChecked(!checked)
    const response = await updatePlugin(data);
    if (response.data && response.errors !== undefined) {
      await refetch_plugin();
    }
  }

  const refetch_plugin = async () => {
    const resp = await refetch();
    console.log("amar: ", resp);
    if (resp?.data.data[0]) {
      console.log("AMAR@", resp?.data.data[0]?.is_active_ind);
      setChecked(resp?.data.data[0]?.is_active_ind);
    }
  };

  useEffect(() => {
    if (show) {
      refetch_plugin()
    }
  },[show])

  return (
    <div>
      <Popup
        modal
        className="plugins-popup-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="plugins-popup-outer">
          <div className="popup-heading">Plugins</div>
          <div className="popup-content-inner">
            {pluginData && pluginData.data.map((pluginData,index) => {
              return <div key={index} className="plugin-outer">
                <div
                  className="plugin-inner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                  <div className="plugin-image-outer"><img src={pluginData.logo} alt="" /></div>
                  <div className="plugin-name">{pluginData.name}</div>
                  <div className="plugin-checkbox-outer">
                  <BootstrapSwitchButton
                      checked={checked}
                      // onlabel='Admin User'
                      // offlabel='Regular User'
                      size="sm"
                      onChange={(checked) => {
                        updatePluginHandler(pluginData.plugin_id)
                      }}
                    />
                    
                    </div>
                </div>
              </div>
            })
            }
          </div>
        </div>
      </Popup>
    </div>
  );
};
