import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Table } from "react-bootstrap";
import twentyFourIcon from "../../assets/icons/ri_24-hours-line.png";
import line_md_log_in from "../../assets/icons/line-md_log-in.png";
import ion_time_outline from "../../assets/icons/ion_time-outline.png";
import ic_round_log_out from "../../assets/icons/ic_round-log-out.png";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useGetAttendanceEmployeesHistoryQuery } from "../../features/users/usersApiSlice";
import { React, useState, useRef, useEffect, useCallback } from "react";
import "./styles.scss";

Chart.register(ArcElement);
const AttendanceHistory = () => {
  const { id } = useParams();

  const { data: employeeRefetchData, refetch: attendance_history, error: employeeRefetchIsError } = useGetAttendanceEmployeesHistoryQuery(id);

  const [countData, setCountData] = useState({
    average_hours: "0h 0m",
    average_punch_in: "0h 0m",
    on_time_arrival: 0,
    average_punch_out: "0h 0m",
    on_time: 0,
    total_checkedin: 0,
    early_arrival: 0,
    late: 0,
    absent: 0,
    total: 0,
  });
  const chartRef = useRef(null);
  const [historyData, setHistoryData] = useState(
    employeeRefetchData ? employeeRefetchData.data.data : []
  );

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          countData.on_time,
          countData.early_arrival,
          countData.late,
          countData.absent,
          countData.on_time +
            countData.early_arrival +
            countData.late +
            countData.absent,
        ],
        backgroundColor: [
          "rgba(76, 175, 80, 1)",
          "#ecd40f",
          "red",
          "#cccccc",
          "#f5f5f5",
        ],
        borderColor: [
          "rgba(76, 175, 80, 1)",
          "#ecd40f",
          "red",
          "#cccccc",
          "#f5f5f5",
        ],
        borderWidth: 0,
      },
    ],
    text: "23%",
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: 60,
    elements: {
      arc: {
        borderRadius: 0,
      },
    },
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text1 = `${countData.total_checkedin}`;
        var textX1 = Math.round((width - ctx.measureText(text1).width) / 2);
        var textY1 = height / 2.5;
        ctx.fillText(text1, textX1, textY1);

        var text2 = `/${countData.total}`;
        var textX2 = Math.round((width - ctx.measureText(text2).width) / 2);
        var textY2 = height / 1.9;
        ctx.fillText(text2, textX2, textY2);
        ctx.save();
      },
    },
  ];

  const get_history_details = async () => {
    const history = await attendance_history();
    setHistoryData(history);
    setCountData({
      average_hours: history?.data?.data?.average_hours,
      average_punch_in: history?.data?.data?.average_checkin_time,
      on_time_arrival: history?.data?.data?.on_time_arrival_percentage,
      average_punch_out: history?.data?.data?.average_checkout_time,
      on_time: history?.data?.data?.counts?.count_checkins_on_time,
      early_arrival: history?.data?.data?.counts?.count_checkins_early,
      late: history?.data?.data?.counts?.count_checkins_late,
      absent: history?.data?.data?.counts?.absent,
      total_checkedin: history?.data?.data?.counts?.total_checkedin,
      total: history?.data?.data?.counts?.total,
    });
  };

  const get_effective_hours = (item) => {
    const checkInTimestamp = item.check_in;
    const checkOutTimestamp = item.check_out;
    if (checkInTimestamp === null) {
      return "--:--";
    } else if (item.check_out === null) {
      return "--:--";
    } else {
      const checkInTime = new Date(checkInTimestamp);
      const checkOutTime = new Date(checkOutTimestamp);

      const timeDifferenceMs = checkOutTime - checkInTime;

      const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hours} hrs :${minutes} minutes`;
    }
  };

  const calculateTimeDifference = (item) => {
    const checkInTimestamp = item.check_in;
    const checkOutTimestamp = item.check_out;
    if (checkInTimestamp === null) {
      return "--:--";
    } else if (item.check_out === null) {
      return "--:--";
    } else {
      const checkInTime = new Date(checkInTimestamp);
      const checkOutTime = new Date(checkOutTimestamp);

      const timeDifferenceMs = checkOutTime - checkInTime;

      const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
      const remainingMinutes = Math.floor(
        (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (hours > 9 || (hours === 9 && remainingMinutes > 0)) {
        const additionalHours = hours - 9;
        const additionalMinutes = remainingMinutes;
        const formattedAdditionalTime = `${additionalHours
          .toString()
          .padStart(2, "0")}:${additionalMinutes.toString().padStart(2, "0")}`;
        return formattedAdditionalTime;
      } else {
        return "--:--";
      }
    }
  };

  const convertToAmPmFormat = (timestamp) => {
    if (timestamp === null) {
      return "--:--";
    }
    const date = new Date(timestamp);

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  const is_today_date = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  };

  useEffect(() => {
    get_history_details();
  }, []);

  useEffect(() => {
    if (employeeRefetchIsError) {
      NotificationManager.error(
        "Failed to fetch employee atendance details.",
        "Error",
        5000
      );
    }
  }, [employeeRefetchIsError]);

  return (
    <section className="layout-inner">
      <div className="attendance-container-outer">
        <div className="heading-outer">
          <div className="page-heading">Attendance </div>
        </div>
        {employeeRefetchData && (
          <div className="attendance-outer">
            <div className="summary-outer">
              <h4>Summary</h4>
              <div className="summary-inner">
                <div className="first-sec">
                  <div
                    className="detail-outer average-hours"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="icon-outer">
                      <img src={twentyFourIcon} />
                    </div>
                    <div className="line-2">Average hours</div>
                    <div className="line-3">{countData.average_hours}</div>
                  </div>
                  <div className="detail-outer on-time-arrival">
                    <div className="icon-outer">
                      <img src={ion_time_outline} />
                    </div>
                    <div className="line-2">On-time Arrival</div>
                    <div className="line-3" style={{ color: "#55ac59" }}>
                      {countData.on_time_arrival} %
                    </div>
                  </div>
                </div>
                <div className="second-sec average-punch-in">
                  <div
                    className="detail-outer"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="icon-outer">
                      <img src={line_md_log_in} />
                    </div>
                    <div className="line-2">Average punch in</div>
                    <div className="line-3">{countData.average_punch_in}</div>
                  </div>
                  <div className="detail-outer average-punch-out">
                    <div className="icon-outer">
                      <img src={ic_round_log_out} />
                    </div>
                    <div className="line-2">Average punch out</div>
                    <div className="line-3">{countData.average_punch_out}</div>
                  </div>
                </div>
                <div className="third-sec">
                  <div className="detail-outer">
                    <h5>My Attendance</h5>
                    <hr />
                    <div className="graph-details-outer">
                      <div className="details">
                        <div className="individual-item">
                          <span
                            className="dot"
                            style={{ backgroundColor: "green" }}
                          ></span>{" "}
                          <span>{countData.on_time}</span>{" "}
                          <span className="line-tag"> on time</span>
                        </div>
                        <div className="individual-item">
                          <span
                            className="dot"
                            style={{ backgroundColor: "yellow" }}
                          ></span>{" "}
                          <span>{countData.early_arrival}</span>{" "}
                          <span className="line-tag">early arrival</span>
                        </div>
                        <div className="individual-item">
                          <span
                            className="dot"
                            style={{ backgroundColor: "red" }}
                          ></span>{" "}
                          <span>{countData.late}</span>{" "}
                          <span className="line-tag">late</span>
                        </div>
                        <div className="individual-item">
                          <span
                            className="dot"
                            style={{ backgroundColor: "grey" }}
                          ></span>{" "}
                          <span>{countData.absent}</span>{" "}
                          <span className="line-tag">absent</span>
                        </div>
                      </div>
                      <div className="graph-inner">
                        <Doughnut
                          redraw
                          data={data}
                          options={options}
                          style={{ width: "10px" }}
                          plugins={plugins}
                          ref={chartRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history-outer">
              <h4>History</h4>
              <div className="history-inner">
                {historyData && (
                  <div className="table-outer">
                    <Table responsive="sm">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Punch In</th>
                          <th>Punch Out</th>
                          <th>Effective Hours</th>
                          <th>Over Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyData?.data?.data?.data &&
                          historyData?.data?.data?.data.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td
                                  style={
                                    is_today_date(item.date)
                                      ? { fontWeight: "bold", color: "#329fef" }
                                      : {}
                                  }
                                >
                                  {is_today_date(item.date)
                                    ? "Today"
                                    : item.date}
                                </td>
                                <td>{convertToAmPmFormat(item.check_in)}</td>
                                <td>{convertToAmPmFormat(item.check_out)}</td>
                                <td>{get_effective_hours(item)}</td>
                                <td>{calculateTimeDifference(item)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AttendanceHistory;
