import axios from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { decryptData } from '../../features/encryption/encryption';
import { updateToken, logOut } from '../../app/hooks/useAuth';

// Base API URL from environment variables
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

// Function to handle the base query and token re-authentication
const baseQueryWithReauth = async (args, api, extraOptions) => {
    const token = api.getState().auth.token;

    // Add the token to the request if it exists
    if (token) {
        const decryptedToken = decryptData(token)
        args.headers = { ...args.headers, Authorization: `Bearer ${decryptedToken}` };
    }

    try {
        try{
            const result = await axiosInstance(args);
            return { data: result.data, meta: result.meta };
        } catch(error) {
            if (error.response.status === 401) {
                const refresh =  decryptData(api.getState().auth.refresh);
                const refreshResult = await axiosInstance.post('accounts/token-refresh/',{"refresh":refresh});
                if (refreshResult?.data?.data) {
                    api.dispatch(updateToken(refreshResult.data.data));
    
                    // Retry the original query with the new token
                    args.headers.Authorization = `Bearer ${refreshResult.data.data.access}`;
                    const result = await axiosInstance.request(args);
                    return { data: result.data, meta: result.meta };
                } else {
                    api.dispatch(logOut());
                }
            }
    
                
        }
    } catch (error) {
        // Handle 401 Unauthorized
        if (error.response?.status === 401 && window.location.pathname !== '/login') {
            api.dispatch(logOut());
            window.location.href = '/login';
        }
        return { error: { status: error.response?.status, data: error.response?.data } };
    }
};

// Define the API slice with the custom base query
export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({}),
});

