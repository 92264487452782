import { createSlice, createSelector } from "@reduxjs/toolkit";
import { encryptData, decryptData } from "../../features/encryption/encryption";

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, refresh:null },
    reducers: {
        setCredentials: (state, action) => {
            const { access,refresh, user_data } = action.payload;
            state.user = encryptData(user_data) || null;
            state.token = encryptData(access) || null;
            state.refresh = encryptData(refresh) || null;
        },
        updateToken: (state, action) => {
            const { access } = action.payload; 
            state.token = encryptData(access) || null;
        },
        logOut: (state) => {
            state.user = null;
            state.token = null;
            state.refresh = null;
        }
    },
});

export const { setCredentials,updateToken, logOut } = authSlice.actions;
export default authSlice.reducer;

// Memoized selectors
export const selectCurrentUser = createSelector(
    (state) => state.auth.user,
    (encryptedUser) => encryptedUser ? decryptData(encryptedUser) : null
);

export const selectCurrentToken = createSelector(
    (state) => state.auth.token,
    (encryptedToken) => encryptedToken ? decryptData(encryptedToken) : null
);

export const selectCurrentRefresh = createSelector(
    (state) => state.auth.refresh,
    (encryptedRefresh) => encryptedRefresh ? decryptData(encryptedRefresh) : null
);
