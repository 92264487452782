import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import "./styles/add_channel.scss";

export const AddChannelPopup = ({ data, level, show, onSubmit, onClose }) => {
  const [channelName, setChannelName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const user = useSelector(selectCurrentUser);
  const handleChannelnNameInput = (e) => {
    setChannelName(e.target.value);
  };

  const submitHandle = () => {
    onSubmit({ name: channelName, participants: selectedMembers });
    onClose();
    setSelectedMembers([]);
    setChannelName("");
  };
  
  useEffect(()=> {
    if(show) {
      setSelectedMembers([user.id])
    }
  }, [show])

  const toggleItem = (itemId) => {
    if (selectedMembers.includes(itemId)) {
      // Item is already selected, so remove it
      setSelectedMembers(selectedMembers.filter((id) => id !== itemId));
    } else {
      // Item is not selected, so add it
      setSelectedMembers([...selectedMembers, itemId]);
    }
  };

  return (
    <div>
      <Popup
        modal
        className="add-channel-popup"
        open={show}
        onClose={() => {
          onClose();
          setSelectedMembers([]);
          setChannelName("");
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
            setSelectedMembers([]);
            setChannelName("");
          }}
        >
          x
        </button>
        <div className="add-channel-outer">
          <div className="popup-heading">Add Channel</div>
          <div className="popup-content-inner">
            <div className="channel-name-outer">
              <div className="name-label">
                Please enter the name of the channel of <span style={{fontWeight: 'bold', color: 'rgb(45 140 241)'}}>{level} Level</span>
              </div>
              <input
                className="channel-name-input"
                onChange={handleChannelnNameInput}
                value={channelName}
                required
              />
            </div>
            <div className="member-select-outer">
              <div className="member-select-label">
                Please add members to this channel
              </div>
              <div className="members-list">
                {data.map((member) => {
                  return (
                    <div className="member-select-item-outer">
                      <input
                        disabled={member.email === user.email}
                        type="checkbox"
                        className="member-select-checkbox"
                        value={member.id}
                        checked={selectedMembers.includes(member.id)}
                        onChange={() => toggleItem(member.id)}
                      />
                      <div className="member-select-item-label">
                        {member.email}{" "}
                        <span className="member-name">
                          ({`${member.first_name} ${member.last_name}`})
                        </span>{" "}
                        {member.email === user.email && (
                          <span
                            style={{
                              background: "#5999fe",
                              padding: "2px 5px",
                              borderRadius: "7px",
                              color: "white",
                            }}
                          >
                            You
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="popup-content-functionality-btn">
            <button
              className={
                selectedMembers.length > 0 && channelName !== ""
                  ? "submit-btn"
                  : "submit-btn submit-btn-disabled"
              }
              onClick={submitHandle}
            >
              Submit
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
