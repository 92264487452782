import React from "react";

const ExperienceSection = () => {
  const experiences = [
    {
      designation: "Software Engineer",
      organization: "Tech Solutions",
      year: "June 2019 - Present",
      location: "San Francisco, CA",
    },
    {
      designation: "Intern Software Developer",
      organization: "InnovateX",
      year: "September 2018",
      location: "New York, NY",
    },
  ];

  return (
          <div className="middle-upper-div">
            <div className="heading-experience">EXPERIENCE</div>
            <div className="experience-data">
              {experiences.map(
                ({ designation, organization, year, location }, idx) => (
                  <div className="history" key={idx}>
                    <div className="left-div">
                      <div className="icon-experience">
                        <div className="ordered-list-dot"></div>
                      </div>
                    </div>
                    <div className="right-div">
                      <div className="designation">{designation}</div>
                      <div className="organization-name">{organization}</div>
                      <div className="working-year">{year}</div>
                      <div className="location">{location}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
  );
};

export default ExperienceSection;
