import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { store } from "../../app/api/store";
import { AiOutlineSend, AiFillEdit, AiOutlineCheck } from "react-icons/ai";
import { ChannelDetailsPopup } from "../../components/popups/ChannelDetailsPopup";
import {
  useGetChannelDetailsQuery,
  useUpdateChannelMessageMutation,
  useGetOrganisationMembersQuery,
} from "../../features/users/usersApiSlice";
import { useGetSubscribedChannelsQuery } from "../../features/channels/channelsApiSlice";
import useWebSocket from "../../components/websocket/websocket";
import {
  selectMessages,
  addMessage,
} from "../../features/channels/channelSlice";
import { setMessages } from "../../features/channels/channelSlice";
import { selectCurrentUser } from "../../app/hooks/useAuth.js";
import "./styles.scss";
import NavDropdown from "react-bootstrap/NavDropdown";
import { AddSubChannelPopup } from "../../components/popups/AddSubChannelPopup.js";
import { NotificationManager } from "react-notifications";

const Channel = () => {
  const { channelId } = useParams();
  const [messageCount, setMessageCount] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [channelLevel, setChannelLevel] = useState(0);
  const [channelIsAdmin, setChannelIsAdmin] = useState(false);
  const [channelIsSender, setChannelIsSender] = useState(false);
  const [channelMembers, setChannelMembers] = useState([]);
  const [channelHierarchy, setChannelHierarchy] = useState([]);
  const [showAddSubChannelPopup, setShowAddSubChannelPopup] = useState(false);
  const [editMessageId, setEditMessageId] = useState(null);
  const [editMessageContent, setEditMessageContent] = useState("");
  const [showChannelDetailPopup, setShowChannelDetailPopup] = useState(false);

  const { fetchMessages, fetchDetails, newChatMessage, addCallbacks } =
    useWebSocket(channelId);

  const {
    data: channelData,
    isLoading: isLoadingChannelData,
    isSuccess: isSuccessChannelData,
    isError: isErrorChannelData,
    error: errorChannelData,
    refetch,
  } = useGetChannelDetailsQuery({
    group_id: channelId,
  });
  const [updateMessage] = useUpdateChannelMessageMutation();

  const [message, setMessage] = useState("");
  let messagesEnd = useRef();
  const messages = useSelector(selectMessages);
  const user = useSelector(selectCurrentUser);

  const { data: members_data } = useGetOrganisationMembersQuery();

  const submitChannelDetailHandler = (data) => {
    setShowChannelDetailPopup(false);
  };
  const onChannelDetailPopupClose = (data) => {
    setShowChannelDetailPopup(false);
  };

  const {
    data: subscribed_channels_data,
    isLoading: channelIsLoading,
    isSuccess: channelIsSuccess,
    isError: channelIsError,
    error: channelError,
    refetch: refetch_subscribed_channels_data,
  } = useGetSubscribedChannelsQuery();

  const refreshManageChannelHandler = useCallback(async () => {
    const new_data = await refetch();
    setChannelIsAdmin(new_data.data.data.has_admin_access);
    setChannelIsSender(new_data.data.data.is_sender);
    setChannelMembers(new_data.data.data.participants);
    setChannelLevel(parseInt(new_data.data.data.level));
    setChannelHierarchy(new_data.data.data.parent_hierarchy);
  }, [refetch]);

  const getLevelName = () => {
    const count = channelLevel;
    switch (count) {
      case 0:
        return "Global";
      case 1:
        return "Continent";
      case 2:
        return "Country";
      case 3:
        return "State";
      case 4:
        return "City";
      case 5:
        return "Local";
      default:
    }
  };

  useEffect(() => {
    const handleMessages = (messages) => {
      store.dispatch(setMessages(messages));
    };

    const handleDetails = (data) => {
      setChannelName(data.name);
      setMessageCount(data.message_count);
    };

    const handleNewMessage = (message) => {
      store.dispatch(addMessage(message));
    };

    addCallbacks(handleMessages, handleDetails, handleNewMessage);

    fetchMessages(user.email, channelId);

    fetchDetails(user.email, channelId);
    refreshManageChannelHandler();
    return () => {
      addCallbacks(null, null);
    };
  }, [
    addCallbacks,
    fetchDetails,
    fetchMessages,
    refreshManageChannelHandler,
    channelId,
    user.email,
  ]);

  useEffect(() => {
    if (errorChannelData) {
      NotificationManager.error("Failed to fetch channel details", "Error");
    }
    if (channelError) {
      NotificationManager.error("Failed to fetch subscribed channels", "Error");
    }
  }, [errorChannelData, channelError]);

  useEffect(() => {
    scrollToBottom();
  });

  const onAddSubChannelPopupClose = (data) => {
    setShowAddSubChannelPopup(false);
  };

  const submitUpdateMessageHandler = async (message_id) => {
    try {
      const update_message_data = {
        message_id,
        group_id: channelId,
        content: editMessageContent,
      };
      const response = await updateMessage(update_message_data);
      if (response.error) {
        NotificationManager.error("Failed to update message", "Error");
      } else {
        const updatedMessages = messages.map((msg) =>
          msg.id === message_id ? { ...msg, content: editMessageContent } : msg
        );
        store.dispatch(setMessages(updatedMessages));
        setEditMessageId(null);
        setEditMessageContent("");
      }
    } catch (error) {
      NotificationManager.error("An unexpected error occurred", "Error");
    }
  };

  const messageChangeHandler = (event) => {
    setMessage(event.target.value);
  };

  const sendMessageHandler = (e) => {
    e.preventDefault();
    const messageObject = {
      from: user.email,
      content: message,
      chatId: channelId,
    };
    newChatMessage(messageObject);
    setMessage("");
  };

  const handleEditMessageContentInput = (e) => {
    setEditMessageContent(e.target.value);
  };

  const renderTimestamp = (timestamp) => {
    let prefix = "";
    const timeDiff = Math.round(
      (new Date().getTime() - new Date(timestamp).getTime()) / 60000
    );
    if (timeDiff < 1) {
      prefix = "just now...";
    } else if (timeDiff < 60 && timeDiff > 1) {
      prefix = `${timeDiff} minutes ago`;
    } else if (timeDiff < 24 * 60 && timeDiff > 60) {
      prefix = `${Math.round(timeDiff / 60)} hours ago`;
    } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
      prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
    } else {
      prefix = `${new Date(timestamp)}`;
    }
    return prefix;
  };

  const renderMessages = (messages) => {
    const currentUser = user.email;
    return messages.map((message, i, arr) => (
      <li
        key={message.id}
        className={message.author === currentUser ? "replies" : "sent"}
      >
        <div className="image-outer">
          <img
            src="http://emilcarlsson.se/assets/mikeross.png"
            alt="profile-pic"
          />
        </div>
        <p className="message-outer">
          <div className="email" style={{ fontWeight: "bold" }}>
            {message.author}{" "}
            {channelData && channelData.data.has_admin_access && (
              <span>
                {editMessageId !== message.id && (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setEditMessageId(message.id);
                      setEditMessageContent(message.content);
                    }}
                  >
                    <AiFillEdit />
                  </button>
                )}
              </span>
            )}
          </div>
          {editMessageId === message.id ? (
            <input
              className="edit-message"
              value={editMessageContent}
              onChange={handleEditMessageContentInput}
            />
          ) : (
            <span>{message.content}</span>
          )}
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ fontSize: "12px" }}>
              {renderTimestamp(message.timestamp)}{" "}
              {message.edited && (
                <span style={{ fontWeight: "bold" }}>Edited</span>
              )}
            </div>
            {editMessageId === message.id && (
              <div>
                <button
                  className="edit-action-btn edit-action-btn-save"
                  onClick={() => {
                    submitUpdateMessageHandler(message.id);
                  }}
                >
                  <AiOutlineCheck />
                </button>
                <button
                  className="edit-action-btn edit-action-btn-cancel"
                  onClick={() => {
                    setEditMessageId(null);
                    setEditMessageContent("");
                  }}
                >
                  X
                </button>
              </div>
            )}
          </div>
        </p>
      </li>
    ));
  };

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="chat-container">
      <div className="contact-profile">
        {user.email !== null ? (
          <div className="channel-header">
            <div
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <NavDropdown
                id="nav-dropdown-dark-example"
                title={`#${channelName}`}
              >
                <NavDropdown.Item href="#action/3.1">
                  Manage Members
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Channel Settings
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Copy Channel Link
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Schedule a Call
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Leave</NavDropdown.Item>
              </NavDropdown>
            </div>
            <div className="details-btn-outer">
              {channelIsAdmin && channelLevel !== 5 && (
                <button
                  className="add-channel-btn"
                  onClick={() => {
                    setShowAddSubChannelPopup(true);
                  }}
                >
                  Create Sub Channel
                </button>
              )}
              <button
                className="details-btn"
                onClick={() => {
                  setShowChannelDetailPopup(true);
                }}
              >
                {members_data && members_data.members.length}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="messages">
        <ul id="chat-log">
          {messages && renderMessages(messages)}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              messagesEnd = el;
            }}
          />
        </ul>
      </div>
      <div className="message-input">
        <form onSubmit={sendMessageHandler}>
          <div className="wrap">
            {channelData &&
              (channelData.data.has_admin_access ? (
                <>
                  <input
                    onChange={messageChangeHandler}
                    value={message}
                    required
                    id="chat-message-input"
                    type="text"
                    placeholder="Write your message..."
                  />
                  <i
                    className="fa fa-paperclip attachment"
                    aria-hidden="true"
                  />
                  <button id="chat-message-submit" className="submit">
                    <AiOutlineSend />
                  </button>
                </>
              ) : channelData.data.is_sender ? (
                <>
                  <input
                    onChange={messageChangeHandler}
                    value={message}
                    required
                    id="chat-message-input"
                    type="text"
                    placeholder="Write your message..."
                  />
                  <i
                    className="fa fa-paperclip attachment"
                    aria-hidden="true"
                  />
                  <button id="chat-message-submit" className="submit">
                    <AiOutlineSend />
                  </button>
                </>
              ) : (
                <div className="not-allowed-chat">
                  You can not send message to this channel
                </div>
              ))}
          </div>
        </form>
      </div>
      <ChannelDetailsPopup
        name={channelName}
        admin={channelIsAdmin}
        sender={channelIsSender}
        id={channelId}
        count={messageCount}
        hierarchy={channelHierarchy}
        data={channelMembers}
        show={showChannelDetailPopup}
        onSubmit={submitChannelDetailHandler}
        onRefresh={refreshManageChannelHandler}
        onClose={onChannelDetailPopupClose}
      />
    </div>
  );
};

export default Channel;
