import React from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const RightSidebar = ({ selectedDate, setSelectedDate, filters, onChangeFilters }) => {
  const startOfMonth = moment(selectedDate).startOf('month');
  const endOfMonth = moment(selectedDate).endOf('month');

  const startDay = startOfMonth.clone().startOf('week'); 
  const endDay = endOfMonth.clone().endOf('week'); 

  const days = [];
  for (let day = startDay; day.isBefore(endDay, 'day'); day.add(1, 'day')) {
    days.push(day.clone());
  }

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handlePrevMonth = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(moment(selectedDate).add(1, 'month'));
  };
  
  const handleChange = (type) => {
    onChangeFilters({
      ...filters,
      [type]: !filters[type],
    });
  };

  return (
    <div className="right-sidebar">
      <div className="calendar-header">
        <FaChevronLeft className="icon" onClick={handlePrevMonth} />
        <span>{moment(selectedDate).format('MMMM YYYY')}</span>
        <FaChevronRight className="icon" onClick={handleNextMonth} />
      </div>

 
      <div className="calendar-weekdays">
        {weekDays.map((day) => (
          <div key={day} className="calendar-weekday">
            {day}
          </div>
        ))}
      </div>

      <div className="calendar-grid">
        {days.map((day) => (
          <div
            key={day}
            className={`calendar-day 
              ${day.isSame(selectedDate, 'day') ? 'selected' : ''} 
              ${day.month() !== selectedDate.month() ? 'out-of-month' : ''}
              ${day.day() === 0 || day.day() === 6 ? 'weekend' : ''}`}
            onClick={() => setSelectedDate(day)}
          >
            {day.format('D')}
          </div>
        ))}
      </div>

      <div className="filter-section">
        <h4>My Calendar</h4>
        <label>
          <input
            type="checkbox"
            checked={filters.meetings}
            onChange={() => handleChange('meetings')}
          />
          Meetings
        </label>
        <label>
          <input
            type="checkbox"
            checked={filters.events}
            onChange={() => handleChange('events')}
          />
          Events
        </label>
        <label>
          <input
            type="checkbox"
            checked={filters.announcements}
            onChange={() => handleChange('announcements')}
          />
          Announcements
        </label>
      </div>
    </div>
  );
};

export default RightSidebar;
