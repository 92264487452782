
import Calendar from "../../components/calendar/Calendar.js";

const CalendarView = () => {


  return (
    <div className="chat-container">
      <Calendar />
    </div>
  );
};

export default CalendarView;
