import Popup from "reactjs-popup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import "./styles/add_location.scss";

export const AddLocationPopup = ({ level, show, onSubmit, onClose }) => {
  const [locationName, setlocationName] = useState("");
  const user = useSelector(selectCurrentUser);

  const handlelocationnNameInput = (e) => {
    setlocationName(e.target.value);
  };

  const submitHandle = () => {
    onSubmit(locationName);
    onClose();
    setlocationName("");
  };


  return (
    <div>
      <Popup
        modal
        className="add-location-popup"
        open={show}
        onClose={() => {
          onClose();
          setlocationName("");
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
            setlocationName("");
          }}
        >
          x
        </button>
        <div className="add-location-outer">
          <div className="popup-heading">Add location</div>
          <div className="popup-content-inner">
            <div className="location-name-outer">
              <div className="name-label">
                Please enter the name of the location of <span style={{fontWeight: 'bold', color: 'rgb(45 140 241)'}}>{level} Level</span>
              </div>
              <input
                className="location-name-input"
                onChange={handlelocationnNameInput}
                maxLength={20}
                value={locationName}
                required
              />
            </div>
          </div>
          <div className="popup-content-functionality-btn">
            <button
              className={
                locationName !== ""
                  ? "submit-btn"
                  : "submit-btn submit-btn-disabled"
              }
              onClick={submitHandle}
            >
              Submit
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
