import React from "react";
import { Heading, LogoAndName, DetailRow } from "./OrganisationInfo"


const OrganisationInfo = ({ user, capitalizeFirstLetter }) => (
    <div className="col-4 organisation-info-outer">
      <Heading text="Organisation" />
      <LogoAndName
        logo={user.organisation.logo}
        name={user.organisation.name}
      />
      <div className="company-details">
        <DetailRow
          label="Name"
          value={`${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)}`}
        />
        <DetailRow label="Description" value={user.organisation.description} />
        <DetailRow label="Established In" value={user.organisation.founded_year} />
        <DetailRow label="Headquarter" value={user.organisation.headquarters} />
        <DetailRow label="Email" value={user.organisation.contact_email} />
        <DetailRow label="Phone" value={user.organisation.phone_number} />
        <DetailRow label="Website" value={user.organisation.website} />
      </div>
    </div>
  );
  
  export default OrganisationInfo;
  