import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';

import { store } from './app/api/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)
root.render(
     <Provider store={store}>
    <BrowserRouter>
    <PersistGate persistor={persistor}>
    <App />
    <NotificationContainer />
    </PersistGate>
    </BrowserRouter>
    </Provider>
);
reportWebVitals();
