import React from "react";
import BachelorIcon from "../../../assets/images/member-details/bachelordegree-icon.svg";
import MasterIcon from "../../../assets/images/member-details/masterdegree-icon.svg";

const EducationSection = () => {
  const educationData = [
    {
      degree: "Bachelor",
      organization: "University of California, Berkeley",
      year: "May 2016",
      gpa: "3.8/4.0",
    },
    {
      degree: "Master",
      organization: "Stanford University",
      year: "June 2019",
      gpa: "4.0/4.0",
    },
  ];

  const icons = {
    Bachelor: BachelorIcon,
    Master: MasterIcon,
  };

  return (
        <div className="left-upper-div">
          <div className="heading-education">EDUCATION</div>
          <div className="education-data">
            {educationData.map(({ degree, organization, year, gpa }, idx) => (
              <div className="history" key={idx}>
                <div className="left-div">
                  <div className={`icon-${degree.toLowerCase()}`}>
                    <img
                      src={icons[degree] || BachelorIcon}
                      alt={`${degree} Icon`}
                    />
                  </div>
                </div>
                <div className="right-div">
                  <div className="course">{degree}</div>
                  <div className="organization-name">{organization}</div>
                  <div className="passing-year">
                    <span className="key">Graduated:</span>
                    <span className="value">{year}</span>
                  </div>
                  <div className="score">
                    <span className="key">GPA:</span>
                    <span className="value">{gpa}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default EducationSection;
