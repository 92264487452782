import React from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarLink = ({ to, icon: Icon, label, handleClick }) => {
  const location = useLocation();

  return (
    <Link to={to} onClick={handleClick}>
      <div
        className={
          location.pathname === to ? "link-active menu-item" : "link-inactive menu-item"
        }
      >
        <Icon style={{ marginRight: "10px" }} />
        {label}
      </div>
    </Link>
  );
};

export default SidebarLink;
