import React from "react";

const SkillsSection = () => {
  const skills = [
    "Python",
    "C++",
    "Javascript",
    "HTML",
    "CSS",
    "React",
    "MySQL",
    "MongoDB",
    "Git",
    "Docker",
  ];

  return (
        <div className="right-upper-div">
          <div className="heading-skills">SKILLS</div>
          <div className="skills-data">
            {skills.map((skill, idx) => (
              <div className="skill-list" key={idx}>
                {skill}
              </div>
            ))}
          </div>
        </div>
  );
};

export default SkillsSection;
