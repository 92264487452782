import Popup from "reactjs-popup";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import {
  useRemoveParticipantsMutation,
  useUpdateParticipantPermissionMutation,
} from "../../features/users/usersApiSlice";
import "./styles/channel_details.scss";

export const ChannelDetailsPopup = ({
  name,
  id,
  count,
  admin,
  sender,
  data,
  hierarchy,
  show,
  onSubmit,
  onRefresh,
  onClose,
}) => {
  const [channelName, setChannelName] = useState("");
  const [tempData, setTempData] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const user = useSelector(selectCurrentUser);

  const [removeParticipants, { isLoading: removeParticipantsIsLoading }] =
    useRemoveParticipantsMutation();
  const [
    updateParticipantPermission,
    { isLoading: updateParticipantPermissionIsLoading },
  ] = useUpdateParticipantPermissionMutation();

  const SubmitRemoveParticipantHandler = async (participant_id) => {
    const remove_data = {
      participants: [participant_id],
      group_id: id,
    };

    const response = await removeParticipants(remove_data);
    await onRefresh({ group_id: id });
    setTempData(data);
  };

  const getLevelName = (level) => {
    const count = level;
    switch (count) {
      case 0:
        return "Global";
        break;
      case 1:
        return "Continent";
        break;
      case 2:
        return "Country";
        break;
      case 3:
        return "State";
        break;
      case 4:
        return "City";
        break;
      case 5:
        return "Local";
        break;
      default:
        break;
    }
  };

  const updatePermissionsHandler = async (new_data) => {
    const permission_data = {
      data: new_data,
      group_id: id,
    };

    const response = await updateParticipantPermission(permission_data);
    await onRefresh({ group_id: id });
    setTempData(data);
  };
  const toggleItem = (event, itemId, type) => {
    if (type === "admin") {
      const updatedData = tempData.map((item) => {
        if (item.id === itemId) {
          // If the user_id matches, update the admin field
          if (event.target.checked) {
            return {
              ...item,
              admin: event.target.checked,
              sender: true,
            };
          } else {
            return {
              ...item,
              admin: event.target.checked,
            };
          }
        }
        return item;
      });
      setTempData(updatedData);
      updatePermissionsHandler(updatedData);
    }
    if (type === "sender") {
      const updatedData = tempData.map((item) => {
        if (item.id === itemId) {
          // If the user_id matches, update the admin field
          return {
            ...item,
            sender: event.target.checked,
          };
        }
        return item;
      });
      setTempData(updatedData);
      updatePermissionsHandler(updatedData);
    }
  };

  useEffect(() => {
    if (show) {
      setTempData(data);
    }
  }, [show, data]);

  const submitHandle = () => {
    onClose();
  };

  return (
    <div>
      <Popup
        modal
        className="channel-details-popup"
        open={show}
        onClose={() => {
          onClose();
        }}
      >
        <button
          className="close-btn"
          onClick={() => {
            onClose();
          }}
        >
          x
        </button>
        <div className="channel-details-outer">
          <div className="popup-heading">Channel Details</div>
          <div className="popup-content-inner">
            <div className="channel-name-outer">
              <div className="name-label">Name</div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#8ca6bc",
                }}
              >
                {name}
              </div>
            </div>
            <div className="channel-name-outer">
              <div className="name-label">Message Count</div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#8ca6bc",
                }}
              >
                {count}
              </div>
            </div>
            <div className="channel-name-outer">
              <div className="name-label">Hierarchy</div>
              {hierarchy.map((item, index) => {
                return (
                  <div style={{ position: "relative", padding: '10px' }}>
                    <span
                      style={{
                        margin: "5px 10px 5px 23px",
                      }}
                    >
                      {item.name}
                    </span>
                    <span
                      style={
                        hierarchy.length === index + 1
                          ? {
                              background: "rgb(37 118 187)",
                              color: "white",
                              padding: "1px 10px",
                              borderRadius: "10px",
                            }
                          : {
                              background: "rgb(152 194 230)",
                              color: "white",
                              padding: "1px 10px",
                              borderRadius: "10px",
                            }
                      }
                    >
                      {getLevelName(item.level)}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "6px",
                        background: "#4ebef0",
                        top: "0px",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        height: "2px",
                        background: "rgb(78, 190, 240)",
                        width: "18px",
                       
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="member-select-outer">
              
              <table className="channel-details-table">
                <thead>
                  <tr>
                    <th>Participant</th>
                    <th>Admin</th>
                    <th>Sender</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tempData.map((member, index) => {
                    const adminCount = tempData.filter((m) => m.admin).length;
                    return (
                      <tr key={index}>
                        <td>
                          <div className="member-select-item-label">
                            {member.email}{" "}
                            {member.email === user.email && (
                              <span
                                style={{
                                  background: "#5999fe",
                                  padding: "2px 5px",
                                  borderRadius: "7px",
                                  color: "white",
                                }}
                              >
                                You
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            {member.email !== user.email && (
                              <input
                                type="checkbox"
                                disabled={
                                  !admin ? true : member.email === user.email
                                }
                                className="member-select-checkbox"
                                value={member.admin}
                                checked={member.admin}
                                onChange={(event) =>
                                  toggleItem(event, member.id, "admin")
                                }
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="checkbox"
                              disabled={
                                !admin ? true : member.email === user.email
                              }
                              className="member-select-checkbox"
                              value={member.sender}
                              checked={member.sender}
                              onChange={(event) =>
                                toggleItem(event, member.id, "sender")
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            {member.email !== user.email ? (
                              <button
                                disabled={!admin}
                                className={
                                  !admin
                                    ? "action-btn action-btn-disabled"
                                    : "action-btn"
                                }
                                onClick={() => {
                                  SubmitRemoveParticipantHandler(member.id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                disabled={adminCount === 1 && member.admin}
                                className={
                                  adminCount === 1 && member.admin
                                    ? "action-btn action-btn-disabled"
                                    : "action-btn"
                                }
                              >
                                Exit
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          
        </div>
      </Popup>
    </div>
  );
};
