import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const PasswordField = ({ id, value, onChange, icon }) => {
  const [showPwd, setShowPwd] = useState(false);

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <>
      <label htmlFor={id}>Password</label>
      <div className="wrapper">
        {icon && <div className="icon">{icon}</div>}
        <input
          type={showPwd ? "text" : "password"}
          id={id}
          value={value}
          placeholder="Password"
          onChange={onChange}
          autoComplete="new-password"
          required
          className="input"
        />
        <div className="toggle-icon" onClick={toggleShowPwd}>
          {showPwd ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </div>
      </div>
    </>
  );
};

export default PasswordField;
