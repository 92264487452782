import React from "react";

const BasicInfo = ({ userData }) => {
  const formatDate = (date) =>
    date
      ? new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : "not available";

  return (
    <div className="member-details-part-two">
      <div className="basic-info">
        <div className="heading-basic-info">BASIC INFORMATION</div>
        <div className="keys-values">
          <div className="column-keys-values">
            <div className="keys-values-section">
              <div className="key">Email ID</div>
              <div className="value">{userData?.data?.email || "not available"}</div>
            </div>
            <div className="keys-values-section">
              <div className="key">Date Joined</div>
              <div className="value">{formatDate(userData?.data?.date_joined)}</div>
            </div>
          </div>
          <div className="column-keys-values">
            <div className="keys-values-section">
              <div className="key">Date of Birth</div>
              <div className="value">{formatDate(userData?.data?.date_of_birth)}</div>
            </div>
            <div className="keys-values-section">
              <div className="key">Designation</div>
              <div className="value">
                {userData?.data?.additional_profile_info?.designation || "not available"}
              </div>
            </div>
          </div>
          <div className="column-keys-values">
            <div className="keys-values-section">
              <div className="key">Gender</div>
              <div className="value">{userData?.data?.basic_user_info?.gender || "not available"}</div>
            </div>
            <div className="keys-values-section">
              <div className="key">Address</div>
              <div className="value">123 Main St, Anytown, USA</div>
            </div>
          </div>
          <div className="column-keys-values">
            <div className="keys-values-section">
              <div className="key">Phone Number</div>
              <div className="value">{userData?.data?.phone_number || "not available"}</div>
            </div>
            <div className="keys-values-section">
              <div className="key">Emergency Contact Number</div>
              <div className="value">234-567-8901</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
