import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { logOut, setCredentials } from "../../app/hooks/useAuth";
import LoginLeftBg from "../../assets/images/login-left-bg.png";
import Logo from "../../assets/images/logo.png";
import LogoBlue from "../../assets/images/logo-blue.png";
import "./styles.scss";
import { Button, InputField, Image, PasswordField } from "../../components/common/index.js";
import { store } from "../../app/api/store.js";

const Login = () => {
  // const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    store.dispatch(logOut());
    // userRef.current.focus();
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(user)) {
      setErrMsg("Invalid email format");
      toast.error("Invalid email format", { toastId: "invalidEmail" });
      return;
    }
    try {
      setLoading(true);
      const userData = await login({ email: user, password: pwd }).unwrap();
      setLoading(false);
      dispatch(setCredentials({ ...userData.data, user }));
      setUser("");
      setPwd("");
      navigate("/home");
    } catch (err) {
      setLoading(false);
      const errorMessage =
        err?.data?.message || "Login Failed";
      toast.error(errorMessage, { toastId: "loginError" });
      errRef.current.focus();
    }
  };

  return (
    <section>
      <ToastContainer />
      <div className="login-outer">
        <div className="login-inner">
          <div className="login-inner-left">
          <Image
              src={LoginLeftBg}
              alt="Background"
              style={{ width: "80%", margin: "auto" }}
            />
            <Image className="left-logo" alt="Logo" src={Logo} />
          </div>
          <div className="login-inner-right">
            <div className="login-sub-inner-right">
              <img className="form-logo-outer" alt="" src={LogoBlue} />
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <div className="login-heading">Login</div>
              <form onSubmit={handleSubmit}>
                <InputField
                  id="email"
                  label="Email"
                  type="text"
                  className="wrapper"
                  inputClassName="input"
                  value={user}
                  placeholder="Email"
                  onChange={(e) => setUser(e.target.value)}
                  icon={<MdOutlineEmail />}
                />
                <PasswordField
                  id="password"
                  className="input"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  icon={<CiLock />}
                />
                <Button type="submit" loading={loading} className="signin-btn">
                  Login
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
