import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../app/hooks/useAuth";
import Select from "react-select";
import { React, useState, useRef, useEffect } from "react";
import { AddLocationPopup } from "../popups/AddLocationPopup";
import { DeleteLocationPopup } from "../popups/DeleteLocationPopup";
import { AddBranchPopup } from "../popups/AddBranchPopup";
import Logo from "../../assets/images/antro-logo.png";
import { BranchDetailPopup } from "../popups/BranchDetailPopup";
import { TailSpin } from 'react-loader-spinner'
import {
  useUpdateOrganisationsMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetUsersQuery,
  useGetOrganisationLocationsQuery,
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useGetPluginsQuery,
  useUpdatePluginMutation
} from "../../features/users/usersApiSlice";
import { useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import {
  AiOutlineMinusCircle
} from "react-icons/ai";
import { NotificationManager } from 'react-notifications';

import { yearList } from "../../app/utils/utils";
import { setCredentials } from "../../app/hooks/useAuth";
import LogoPlaceholder from "../../../src/assets/images/logo-placeholder.png";


import "./new_organisation.scss";

import {countries} from 'countries-list'
import LocationTree from "../sitemapMobile";


const NewOrgansation = () => {
  const user = useSelector(selectCurrentUser);
 

  const token = useSelector(selectCurrentToken);
  const [activeKey, setActiveKey] = useState("tab1");
  const [organisationName, setOrganisationName] = useState(user && user.organisation.name_nn);
  const [organisationWebsite, setOrganisationWebsite] = useState(user.organisation.website);
  const [organisationDescription, setOrganisationDescription] = useState(user.organisation.description);
  const [organisationLocation, setOrganisationLocation] = useState(user.organisation.headquarters);
  const [organisationCategory, setOrganisationCategory] = useState(user.organisation.industry);
  const [organisationPhone, setOrganisationPhone] = useState("");
  const [organisationFoundedYear, setOrganisationFoundedYear] = useState(user.organisation.founded_year);
  const [organisationEmail, setOrganisationEmail] = useState(user.organisation.contact_email);
  const [showAddLocationPopup, setShowAddLocationPopup] = useState(false);
  const [showAddBranchPopup, setShowAddBranchPopup] = useState(false);
  const [showBranchDetailsPopup, setShowBranchDetailsPopup] = useState(false);
  const [showDeleteLocationPopup, setShowDeleteLocationPopup] = useState(false);
  const [newLocationDirectoryIndex, setNewLocationDirectoryIndex] = useState(0);
  const [newLocationParent, setNewLocationParent] = useState("");
  const [tab1loading, setTab1loading] = useState(false)
  const [locationUpdateLoading, setLocationUpdateLoading] = useState(false)
  const [deleteLocationDirectoryIndex, setDeleteLocationDirectoryIndex] =
    useState(0);
  const [deleteLocationName, setDeleteLocationName] = useState("");
  const [deleteLocationId, setDeleteLocationId] = useState("");
  const [branchDetailsData, setBranchDetailsData] = useState({});
  const [newBranchLocationName, setNewBranchLocationName] = useState("");
  const [newBranchLocationId, setNewBranchLocationId] = useState("");
  const [year, setYear] = useState({
    value: user.organisation.founded_year,
    label: user.organisation.founded_year,
  });
  const fileInputRef = useRef(null);
  const [country, setCountry] = useState({
    value: 91,
    label: "India (+91)"
  })
  const [countriesList, setCountriesList] = useState([])
  const [organisationError, setorganisationError] = useState({
    image: null,
    name: null,
    email: null,
    phoneNumber: null,
    location: null,
    website: null,
    industryCategory: null,
    description: null
  })


  const { refetch: refetch_users } =
    useGetUsersQuery();
  const {
    data: locationData,
    refetch: refetch_locations,
  } = useGetOrganisationLocationsQuery({ parent_id: null });
  const dispatch = useDispatch();

  const {
    data: pluginData,
    refetch: refetch_plugin,
  } = useGetPluginsQuery();

  const [
    updatePlugin,
  ] = useUpdatePluginMutation();

  const checkDataValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newError = {
      image: null,
      name: null,
      email: null,
      phoneNumber: null,
      location: null,
      website: null,
      industryCategory: null,
      description: null
    }
    if (!user.organisation.logo && logoFile === undefined) {
      newError.image = "Upload proper image"
    }
    if (organisationName.length < 2) {
      newError.name = "Enter name greater than 2 character length"
    }
    if (organisationLocation === "") {
      newError.location = "Enter proper location"
    }
    if (!emailRegex.test(organisationEmail)) {
      newError.email = "Invalid Email"
    }
    if (organisationPhone === "") {
      newError.phoneNumber = "Invalid Phone number"
    }
    if (organisationWebsite === "") {
      newError.website = "Invalid Website"
    }
    if (organisationCategory.length < 2) {
      newError.industryCategory = "Enter category greater than 2 character length"
    }
    if (organisationDescription.length < 10) {
      newError.description = "Enter description greater than 10 character length"
    }
    setorganisationError(newError)

    const hasNullValue = Object.values(newError).some(value => value !== null);

    if (hasNullValue) {
      return false
    } else {
      return true
    }

  }


  const [updateOrganisation] = useUpdateOrganisationsMutation();

  const [createLocation] =  useCreateLocationMutation();

  const [deleteLocation] =useDeleteLocationMutation();

  const [deleteBranch] =useDeleteBranchMutation();

  const [createBranch] =useCreateBranchMutation();

  const [logoFile, setLogoFile] = useState();

  const handleOrganisationNameInput = (e) => {
    setOrganisationName(e.target.value);
  };
  const handleOrganisationWebsiteInput = (e) => {
    setOrganisationWebsite(e.target.value);
  };
  const handleOrganisationEmailInput = (e) => {
    setOrganisationEmail(e.target.value);
  };
  const handleOrganisationDescriptionInput = (e) => {
    setOrganisationDescription(e.target.value);
  };
  const handleOrganisationLocationInput = (e) => {
    setOrganisationLocation(e.target.value);
  };
  const handleOrganisationCategoryInput = (e) => {
    setOrganisationCategory(e.target.value);
  };
  const handleOrganisationPhoneInput = (e) => {
    if (e.target.value.toString().length > 10) {
      return; 
    }
    setOrganisationPhone(e.target.value);
  };
  

  const handleOrganisationLogo = (event) => {
    setLogoFile(event.target.files[0]);
    setorganisationError({image:""})
  
  };

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  const onAddLocationPopupClose = () => {
    setNewLocationParent("");
    setNewLocationDirectoryIndex(0);
    setShowAddLocationPopup(false);
  };
  const onAddBranchPopupClose = () => {
    setNewBranchLocationName("");
    setNewBranchLocationId("");
    setShowAddBranchPopup(false);
  };
  const onBranchDetailsPopupClose = () => {
    setBranchDetailsData("");
    setShowBranchDetailsPopup(false);
  };
  const onDeleteLocationPopupClose = () => {
    setDeleteLocationName("");
    setDeleteLocationId("");
    setDeleteLocationDirectoryIndex(0);
    setShowDeleteLocationPopup(false);
  };
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const getLevelName = () => {
    const count = newLocationDirectoryIndex + 1;
    switch (count) {
      case 0:
        return "Global";
      case 1:
        return "Continent";
      case 2:
        return "Country";
      case 3:
        return "State";
      case 4:
        return "City";
      case 5:
        return "Local";
      default:
        return "Location"
    }
  };
  const [yearOptions] = useState(yearList);

  const setYearHandler = (e) => {
    setYear(e);
    setOrganisationFoundedYear(e.value);
  };
  const setCountryHandler = (e) => {
    setCountry(e)
  }
  
  useEffect(() => {
    const temp_countries = Object.entries(countries).map(([code, country]) => ({
      label: `${country.name_nn} (+${country.phone[0].toString()})`,
      value: country.phone[0].toString()
    }));
    setCountriesList(temp_countries)
   
    refetch_locations();
    const lastTenDigits = user.organisation.phone_number.substring(user.organisation.phone_number.length - 10);
    setOrganisationPhone(lastTenDigits)
    let modifiedUrl = user.organisation.website.replace("https://", "");
    modifiedUrl = modifiedUrl.replace("http://", "");
    setOrganisationWebsite(modifiedUrl)
  }, [refetch_locations, user.organisation.phone_number, user.organisation.website]);


  const SubmitOrganisationHandler = async () => {
    if (!checkDataValidation()) {
      return false
    }
    setTab1loading(true)
    const formData = new FormData();
    formData.append("name", organisationName);
    formData.append("website", `https://${organisationWebsite}`);
    formData.append("description", organisationDescription);
    formData.append("founded_year", organisationFoundedYear);
    formData.append("headquarters", organisationLocation);
    formData.append("industry", organisationCategory);
    formData.append("contact_email", organisationEmail);
    formData.append("phone_number", `+${country.value}${organisationPhone}`);
    if (logoFile){
      formData.append("logo", logoFile)
    }

    const response = await updateOrganisation(formData);
    setTab1loading(false)
    if (response.data.data && response.errors === undefined) {
      await refetch_locations()
      setActiveKey("tab4");
     
    }

    const result = await refetch_users();
    if (result && result.data && result.data.data && result.data.response_data) {
      dispatch(setCredentials({ user: null, user_data: result.data.response_data.user_data, access: token }))
    };
  }
  const SubmitAddLocationHandler = async (level, parent) => {
    setNewLocationParent(parent);
    setNewLocationDirectoryIndex(level);
    setShowAddLocationPopup(true);
  };

  const SubmitBranchDetailHandler = async (data) => {
    setBranchDetailsData(data);
    setShowBranchDetailsPopup(true);
  };
  const SubmitAddBranchHandler = async (id, name) => {
    setNewBranchLocationId(id);
    setNewBranchLocationName(name);
    setShowAddBranchPopup(true);
  };

  const SubmitDeleteLocationHandler = async (id, level, name) => {
    console.log("AAAAAA: ", id, level, name);
    setDeleteLocationName(name);
    setDeleteLocationId(id);
    setDeleteLocationDirectoryIndex(level);
    setShowDeleteLocationPopup(true);
  };

  const SubmitAddLocationPopupHandler = async (name) => {

    setLocationUpdateLoading(true)
    const data = {
      name_nn: name,
      level: newLocationDirectoryIndex + 1,
      parent_fk: newLocationParent,
    };
  

    const response = await createLocation(data);
    if (response.data) {
      refetch_locations();
    }
    setLocationUpdateLoading(false)
  };
  const updatePluginHandler = async (id) => {
    const data = {
      id: id,
      initial: true
    }
    const response = await updatePlugin(data);
    if (response.data) {
      const result = await refetch_users();
      if (result?.data) {

        dispatch(setCredentials({
          user: null,
          user_data: result.data.data.user_data,
          access: token
        }));
        window.location.reload();
        NotificationManager.success('Onboarding Started', 'Members onbaording started');
      }

    }
  }
  const SubmitAddBranchPopupHandler = async ({
    branchName,
    branchAddress,
    branchPhone,
  }) => {
    const data = {
      location_fk: newBranchLocationId,
      branch_name_nn: branchName,
      branch_address: branchAddress,
      branch_phone: branchPhone,
    };

    const response = await createBranch(data);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
  };

  const SubmitDeleteLocationPopupHandler = async () => {
    const data = {
      location_id: deleteLocationId,
    };

    const response = await deleteLocation(data);
    if (response.data && response?.errors === undefined) {
      refetch_locations();
    }
  };

  const SubmitBranchDetailsPopupHandler = async () => {
    const data = {
      branch_id: branchDetailsData.branch_id,
    };

    const response = await deleteBranch(data);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
  };

  return (
    <div className="add-organisation-outer">
      <div className="left-side-panel" style={{
        flex: "0 1 22%", padding: "20px", borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, -0.85) 0px 5px 15px 0px", backgroundColor: "#F2FAFF",
      }}>
        <div className="logo-section">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="workspace-welcome">Welcome to Your New Workspace!</div>
        </div>
        <ul className="steps">
          <li className={`step ${["tab1", "tab4", "tab5"].includes(activeKey) && "active"}`}>
            <div className="step-icon">1</div>
            <div className="step-label">Create Organisation</div>

          </li>
          <li className={`step ${["tab4", "tab5"].includes(activeKey) && "active"}`}>
            <div className="step-icon">2</div>
            <div className="step-label">Organisation Location</div>
            <div className="step-line"></div>
          </li>
          <li className={`step ${["tab5"].includes(activeKey) && "active"}`}>
            <div className="step-icon">3</div>
            <div className="step-label">Add Members</div>
            <div className="step-line"></div>
          </li>
        </ul>
      </div>
      <div className="right-side-content" style={{ flex: 2, padding: "20px" }}>
        <Tabs
          id="controlled-tab-example"
          activeKey={activeKey}
          onSelect={handleTabSelect}
        >
          <Tab eventKey="tab1" title="Tab 1">
            <div
              className={`tab-content ${activeKey === "tab1" ? "active-tab tab" : "tab"
                }`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >


              <div className="organisation-name-outer">
                <div className="tab-heading">Create Organisation</div>
                <div className="tab-sub-heading">Upload a logo and update your organisation information.</div>
                {tab1loading && <div className="loading-component">
                  <TailSpin
                    visible={true}
                    height="50"
                    width="50"
                    color="#03a9f4"
                    ariaLabel="tail-spin-loading"
                    radius="2"
                  />
                </div>
                }
                <div className="organisation-name-inner" style={tab1loading ? { filter: 'blur(2px)' } : {}}>
                  <div className="organisation-logo-outer group-pair">

                    <div className="label">Organisation Photo</div>
                    <div className="input-outer">
                      <div
                        className="organisation-logo"
                        style={logoFile !== null ? { border: '2px solid #bfdbf8' } : {}}
                      >
                        <img
                          alt=""
                          src={logoFile ? URL.createObjectURL(logoFile) : (user.organisation.logo? user.organisation.logo: LogoPlaceholder)}
                        />
                      </div>
                      <div className="upload-section">
                        <div className="file-type">.png, .jpeg, .jpg, .git files upto 5 MB.</div>
                        <div className="file-limit">Recommeded size is 250x250px</div>
                        <div className="upload-btn-outer">
                          <button
                            className="upload-logo-btn-outer"
                            onClick={openFileInput}
                          >
                            Choose Image
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            id="organisation-name"
                            accept=".png .jpeg, .jpg"
                            onChange={handleOrganisationLogo}
                          />
                        </div>
                        {organisationError.image !== null && <div className="error-message">{organisationError.image}</div>}
                      </div>
                    </div>

                  </div>
                  <div className="group-pair" >
                    <div className="label">
                      Organisation Name
                    </div>
                    <div className="input-outer">
                      <input
                        type="text"
                        id="organisation-name"
                        className="input-field"
                        placeholder="Enter your organisation name"
                        onChange={handleOrganisationNameInput}
                        value={organisationName}
                        required
                      />
                      {organisationError.name !== null && <div className="error-message">{organisationError.name}</div>}
                    </div>
                  </div>

                  <div className="group-pair">
                    <div className="label">Established In</div>
                    <div className="input-outer">
                      <Select
                        isSearchable={false}
                        className="dropdown-item"
                        value={year}
                        onChange={setYearHandler}
                        options={yearOptions}
                      />
                    </div>
                  </div>


                  <div className="group-pair">
                    <div className="label">
                      Headquater
                    </div>
                    <div className="input-outer">
                      <input
                        type="text"
                        id="organisation-location"
                        className="input-field"
                        placeholder="Enter your organisation location"
                        onChange={handleOrganisationLocationInput}
                        value={organisationLocation}
                        maxLength={40}
                        required
                      />
                      {organisationError.location !== null && <div className="error-message">{organisationError.location}</div>}
                    </div>
                  </div>

                  <div className="group-pair">
                    <div className="label">Email</div>
                    <div className="input-outer">
                      <input
                        type="email"
                        id="organisation-email"
                        className="input-field"
                        placeholder="Enter your email address"
                        onChange={handleOrganisationEmailInput}
                        value={organisationEmail}
                        maxLength={40}
                        required
                      />
                      {organisationError.email !== null && <div className="error-message">{organisationError.email}</div>}
                    </div>
                  </div>


                  <div className="group-pair">
                    <div className="label">Phone Number</div>
                    <div className="input-outer" style={{ display: 'flex', alignItems: "center", flexDirection: "row" }}>
                      <Select
                        isSearchable={false}
                        className="dropdown-item"
                        styles={{ width: "30%" }}
                        value={country}
                        onChange={setCountryHandler}
                        options={countriesList}
                      />
                      <input
                        type="number"
                        max='99999999999'
                        id="organisation-phone"
                        className="input-field"
                        onChange={handleOrganisationPhoneInput}
                        value={organisationPhone}
                        required
                      />
                      {organisationError.phoneNumber !== null && <div className="error-message">{organisationError.phoneNumber}</div>}
                    </div>
                  </div>

                  <div className="group-pair">
                    <div className="label">
                      Website
                    </div>
                    <div className="input-outer" style={{ display: 'flex', flexDirection: "row" }}>
                      <input
                        className="input-field"
                        disabled
                        style={{
                          flexGrow: 0,
                          width: '126px',
                          marginRight: '10px'
                        }}
                        value="https://"
                      />
                      <input
                        type="text"
                        id="organisation-website"
                        className="input-field"
                        placeholder="Enter your organisation email address"
                        style={{ flexGrow: 1 }}
                        onChange={handleOrganisationWebsiteInput}
                        value={organisationWebsite}
                        maxLength={40}
                        required
                      />
                      {organisationError.website !== null && <div className="error-message">{organisationError.website}</div>}
                    </div>
                  </div>

                  <div className="group-pair" >
                    <div className="label">Industry Category</div>
                    <div className="input-outer">
                      <input
                        type="text"
                        id="organisation-category"
                        className="input-field"
                        placeholder="Enter your organisation category"
                        onChange={handleOrganisationCategoryInput}
                        value={organisationCategory}
                        maxLength={30}
                        required
                      />
                      {organisationError.industryCategory !== null && <div className="error-message">{organisationError.industryCategory}</div>}
                    </div>
                  </div>

                  <div className="group-pair">
                    <div className="label">Description</div>
                    <div className="input-outer">
                      <input
                        type="text"
                        id="organisation-description"
                        className="input-field"
                        placeholder="Enter your description"
                        onChange={handleOrganisationDescriptionInput}
                        value={organisationDescription}
                        required
                      />
                      {organisationError.description !== null && <div className="error-message">{organisationError.description}</div>}
                    </div>
                  </div>
                  <div className="nav-buttons-outer">
                    <span></span>
                    <button
                      className={
                        organisationName !== ""
                          ? "nav-btn next-btn"
                          : "nav-btn next-btn nav-btn-inactive"
                      }
                      disabled={tab1loading}
                      onClick={() => {
                        SubmitOrganisationHandler();
                      }}
                    >
                      {tab1loading && <div
                        style={{ marginRight: "10px" }}
                      ><TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#ffffff"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                        />
                      </div>
                      }
                      <div>
                        {tab1loading ? "Saving" : "Next"}
                      </div>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </Tab>
          <Tab eventKey="tab2" title="Tab 2">
            <div
              className={`tab-content ${activeKey === "tab2" ? "active-tab tab" : "tab"
                }`}
            >
              <div className="tab-heading">Organisation Logo</div>
              <div className="organisation-name-outer">
                <div style={{ flexGrow: 1 }}>
                  <div className="organisation-name-label-sub">
                    This logo we reflect throughout your organition for all
                    members linked.
                  </div>
                  <div className="organisation-name-label">
                    Please upload you logo in png format and 50px X 50px
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="organisation-name"
                      accept=".png"
                      onChange={handleOrganisationLogo}
                    />
                  </div>
                </div>
              </div>
              <div className="nav-buttons-outer">
                <button
                  className="nav-btn back-btn"
                  onClick={() => {
                    setActiveKey("tab1");
                  }}
                >
                  Back
                </button>
                <button
                  className={
                    logoFile
                      ? "nav-btn next-btn"
                      : "nav-btn next-btn nav-btn-inactive"
                  }
                  onClick={() => {
                    setActiveKey("tab3");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </Tab>
          <Tab eventKey="tab3" title="Tab 3">
            <div
              className={`tab-content ${activeKey === "tab3" ? "active-tab tab tab3" : "tab tab3"
                }`}
            >
              <div className="tab-heading">Organisation Details</div>
              <div className="organisation-name-outer">
                <div style={{ flexGrow: 1 }}>
                  <div className="organisation-name-label-sub">
                    This name we reflect throughout your organition for all
                    members linked.
                  </div>
                  <div className="organisation-name-label">Description</div>
                  <div>
                    <input
                      type="text"
                      id="organisation-description"
                      className="input-field"
                      onChange={handleOrganisationDescriptionInput}
                      value={organisationDescription}
                      required
                    />
                  </div>
                  <div className="organisation-name-label">
                    Please enter your organisation website if any
                  </div>
                  <div>
                    <input
                      type="text"
                      id="organisation-website"
                      className="input-field"
                      onChange={handleOrganisationWebsiteInput}
                      value={organisationWebsite}
                      required
                    />
                  </div>

                  <div className="organisation-name-label">
                    Main Branch Location
                  </div>
                  <div>
                    <input
                      type="text"
                      id="organisation-location"
                      className="input-field"
                      onChange={handleOrganisationLocationInput}
                      value={organisationLocation}
                      required
                    />
                  </div>
                  <div className="organisation-name-label">Industry Category</div>
                  <div>
                    <input
                      type="text"
                      id="organisation-category"
                      className="input-field"
                      onChange={handleOrganisationCategoryInput}
                      value={organisationCategory}
                      required
                    />
                  </div>
                  <div className="organisation-name-label">Contact Email</div>
                  <div>
                    <input
                      type="email"
                      id="organisation-email"
                      className="input-field"
                      onChange={handleOrganisationEmailInput}
                      value={organisationEmail}
                      required
                    />
                  </div>
                  <div className="organisation-name-label">Contact Number</div>
                  <div>
                    <input
                      type="text"
                      id="organisation-phone"
                      className="input-field"
                      onChange={handleOrganisationPhoneInput}
                      value={organisationPhone}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="nav-buttons-outer">
                <button
                  className="nav-btn back-btn"
                  onClick={() => {
                    setActiveKey("tab2");
                  }}
                >
                  Back
                </button>
                <button
                  className={
                    organisationDescription !== "" &&
                      organisationWebsite !== "" &&
                      organisationFoundedYear !== "" &&
                      organisationLocation !== "" &&
                      organisationEmail !== "" &&
                      organisationPhone !== ""
                      ? "nav-btn next-btn"
                      : "nav-btn next-btn nav-btn-inactive"
                  }
                  onClick={() => {
                    SubmitOrganisationHandler();
                  }}
                >
                  Save And Proceed
                </button>
              </div>
            </div>
          </Tab>
          <Tab eventKey="tab4" title="Tab 4">
            <div
              className={`tab-content ${activeKey === "tab4" ? "active-tab tab" : "tab"
                }`}
            >

              {locationUpdateLoading && <div className="loading-component">
                <TailSpin
                  visible={true}
                  height="50"
                  width="50"
                  color="#03a9f4"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                />
              </div>
              }
              <div className="organisation-location-outer" style={locationUpdateLoading ? { filter: 'blur(2px)' } : {}}>
                <div className="tab-heading">Organisation Location</div>
                <div className="tab-sub-heading">Set Your Office Location for Seamless Coordination.</div>
                <LocationTree
                  data={locationData?.data[0]}
                  SubmitAddLocationHandler={SubmitAddLocationHandler}
                  SubmitDeleteLocationHandler={SubmitDeleteLocationHandler}
                  SubmitAddBranchHandler={SubmitAddBranchHandler}
                  SubmitBranchDetailHandler={SubmitBranchDetailHandler}
                />
                <div className="organisation-location-inner">
                  {locationData &&
                    locationData.data &&
                    locationData.data.map((globalItem) => {
                      return (
                        <div className="row location-data-outer">
                          <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                            <div className="location-data-container">
                              <div className="location-name">
                                {globalItem.name_nn}
                              </div>
                              <div className="location-func-btns">

                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                            <button
                              className="add-location"
                              onClick={() => {
                                SubmitAddLocationHandler(0, globalItem.location_id);
                              }}
                            >
                              <span>
                                <FaPlus />
                              </span>
                              Add Continent{" "}
                            </button>
                          </div>
                          {globalItem.sublocations.map(
                            (continentItem, continenetIndex) => {
                              return (
                                <div
                                  className={
                                    continenetIndex <
                                      globalItem.sublocations.length - 1
                                      ? "row side-marker-continent"
                                      : "row"
                                  }
                                >
                                  <div className="curve-marker-mobile"> -- </div>
                                  <div className="col-2 curve-marker curve-marker-continent">
                                    <div className="curve curve-continent"></div>
                                  </div>
                                  <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                    <div className="location-data-container">
                                      <div className="location-name">
                                        {continentItem.name_nn}
                                      </div>
                                      <div className="location-func-btns">


                                        <button
                                          className="remove-location"
                                          onClick={() => {
                                            SubmitDeleteLocationHandler(
                                              continentItem?.location_id,
                                              1,
                                              continentItem?.name_nn
                                            );
                                          }}
                                        >
                                          <AiOutlineMinusCircle />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2 location-data-inner">
                                    <button
                                      className="add-location"
                                      onClick={() => {
                                        SubmitAddLocationHandler(
                                          1,
                                          continentItem.location_id
                                        );
                                      }}
                                    >
                                      <span>
                                        <FaPlus />
                                      </span>
                                      Add Country{" "}
                                    </button>
                                  </div>
                                  {continentItem.sublocations.map(
                                    (countryItem, countryIndex) => {
                                      return (
                                        <div
                                          className={
                                            countryIndex <
                                              continentItem.sublocations.length - 1
                                              ? "row side-marker-country"
                                              : "row"
                                          }
                                        >
                                          <div className="curve-marker-mobile"> -- </div>
                                          <div className="col-4 curve-marker curve-marker-country">
                                            <div className="curve curve-country"></div>
                                          </div>
                                          <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                            <div className="location-data-container">
                                              <div className="location-name">
                                                {countryItem.name_nn}
                                              </div>
                                              <div className="location-func-btns">

                                                <button
                                                  className="remove-location"
                                                  onClick={() => {
                                                    SubmitDeleteLocationHandler(
                                                      countryItem.id,
                                                      2,
                                                      countryItem.name_nn
                                                    );
                                                  }}
                                                >
                                                  <AiOutlineMinusCircle />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-2 col-md-12 col-sm-12  location-data-inner">
                                            <button
                                              className="add-location"
                                              onClick={() => {
                                                SubmitAddLocationHandler(
                                                  2,
                                                  countryItem.location_id
                                                );
                                              }}
                                            >
                                              <span>
                                                <FaPlus />
                                              </span>
                                              Add State{" "}
                                            </button>
                                          </div>
                                          {countryItem.sublocations.map(
                                            (stateItem, stateIndex) => {
                                              return (
                                                <div
                                                  className={
                                                    stateIndex <
                                                      countryItem.sublocations
                                                        .length -
                                                      1
                                                      ? "row side-marker-state"
                                                      : "row"
                                                  }
                                                >
                                                  <div className="curve-marker-mobile"> -- </div>
                                                  <div className="col-6 curve-marker curve-marker-state">
                                                    <div className="curve curve-state"></div>
                                                  </div>
                                                  <div className="col-lg-2 col-md-12 col-sm-12  location-data-inner">
                                                    <div className="location-data-container">
                                                      <div className="location-name">
                                                        {stateItem.name_nn}
                                                      </div>
                                                      <div className="location-func-btns">
                                                        <button
                                                          className="remove-location"
                                                          onClick={() => {
                                                            SubmitDeleteLocationHandler(
                                                              stateItem.id,
                                                              3,
                                                              stateItem.name_nn
                                                            );
                                                          }}
                                                        >
                                                          <AiOutlineMinusCircle />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                                    <button
                                                      className="add-location"
                                                      onClick={() => {
                                                        SubmitAddLocationHandler(
                                                          3,
                                                          stateItem.location_id
                                                        );
                                                      }}
                                                    >
                                                      <span>
                                                        <FaPlus />
                                                      </span>
                                                      Add City{" "}
                                                    </button>
                                                  </div>
                                                  {stateItem.sublocations.map(
                                                    (cityItem, cityIndex) => {
                                                      return (
                                                        <div
                                                          className={
                                                            cityIndex <
                                                              stateItem.sublocations
                                                                .length -
                                                              1
                                                              ? "row side-marker-city"
                                                              : "row"
                                                          }
                                                        >
                                                          <div className="curve-marker-mobile"> -- </div>
                                                          <div className="col-8 curve-marker curve-marker-city">
                                                            <div className="curve curve-city"></div>
                                                          </div>
                                                          <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                                            <div className="location-data-container">
                                                              <div className="location-name">
                                                                {cityItem.name_nn}
                                                              </div>
                                                              <div className="location-func-btns">
                                                                <button
                                                                  className="remove-location"
                                                                  onClick={() => {
                                                                    SubmitDeleteLocationHandler(
                                                                      cityItem.id,
                                                                      4,
                                                                      cityItem.name_nn
                                                                    );
                                                                  }}
                                                                >
                                                                  <AiOutlineMinusCircle />
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                                            <button
                                                              className="add-location"
                                                              onClick={() => {
                                                                SubmitAddLocationHandler(
                                                                  4,
                                                                  cityItem.location_id
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                <FaPlus />
                                                              </span>
                                                              Add Local{" "}
                                                            </button>
                                                          </div>
                                                          {cityItem.sublocations.map(
                                                            (
                                                              localItem,
                                                              localIndex
                                                            ) => {
                                                              return (
                                                                <div
                                                                  className={
                                                                    localIndex <
                                                                      cityItem
                                                                        .sublocations
                                                                        .length -
                                                                      1
                                                                      ? "row side-marker-local"
                                                                      : "row"
                                                                  }
                                                                >
                                                                  <div className="curve-marker-mobile"> -- </div>
                                                                  <div className="col-10 curve-marker curve-marker-local">
                                                                    <div className="curve curve-local"></div>
                                                                  </div>
                                                                  <div className="col-lg-2 col-md-12 col-sm-12 location-data-inner">
                                                                    <div className="location-data-container">
                                                                      <div className="location-name">
                                                                        {
                                                                          localItem.name_nn
                                                                        }
                                                                      </div>
                                                                      <div className="location-func-btns">
                                                                        <button
                                                                          className="remove-location"
                                                                          onClick={() => {
                                                                            SubmitDeleteLocationHandler(
                                                                              localItem.id,
                                                                              5,
                                                                              localItem.name_nn
                                                                            );
                                                                          }}
                                                                        >
                                                                          <AiOutlineMinusCircle />
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                    {localItem
                                                                      .branches
                                                                      .length <
                                                                      1 ? (
                                                                      <button
                                                                        className="add-branch-outer"
                                                                        onClick={() => {
                                                                          SubmitAddBranchHandler(
                                                                            localItem.location_id,
                                                                            localItem.name_nn

                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="add-branch-inner">
                                                                          Add
                                                                          Branch
                                                                        </div>
                                                                      </button>
                                                                    ) : (
                                                                      <div className="branches-outer">
                                                                        <div className="branches-heading">
                                                                          Branches
                                                                        </div>
                                                                        {localItem.branches.map(
                                                                          (
                                                                            item
                                                                          ) => {
                                                                            return (
                                                                              <button
                                                                                className="branch"
                                                                                onClick={() => {
                                                                                  SubmitBranchDetailHandler(
                                                                                    item
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <div className="branch-name">
                                                                                  {
                                                                                    item.branch_name_nn
                                                                                  }
                                                                                </div>
                                                                              </button>
                                                                            );
                                                                          }
                                                                        )}
                                                                        <div className="add-branch-outer">
                                                                          <button
                                                                            className="add-branch-inner"
                                                                            onClick={() => {
                                                                              SubmitAddBranchHandler(
                                                                                localItem.location_id,
                                                                                localItem.name_nn
                                                                              );
                                                                            }}
                                                                          >
                                                                            Add
                                                                            Branch
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="nav-buttons-outer">
                <div></div>
                <button
                  className="nav-btn next-btn"
                  onClick={() => {
                    refetch_plugin()
                    setActiveKey("tab5");
                  }}
                >
                  {locationUpdateLoading && <div
                    style={{ marginRight: "10px" }}
                  ><TailSpin
                      visible={true}
                      height="20"
                      width="20"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="3"
                    />
                  </div>
                  }
                  <div>
                    {locationUpdateLoading ? "Saving" : "Save"}
                  </div>
                </button>
              </div>
            </div>
          </Tab>

          <Tab eventKey="tab5" title="Tab 5">
            <div
              className={`tab-content ${activeKey === "tab5" ? "active-tab tab tab5" : "tab tab5"
                }`}
            >
              <div className="organisation-name-outer">
                <div style={{ flexGrow: 1 }}>
                  <h2 className="tab-heading">Add Members</h2>
                  <p className="organisation-name-label-sub">
                    Please select one of the available plugins to start automated onboarding
                  </p>
                  <div className="plugins-list">
                    {pluginData &&
                      pluginData.data.map((plugin) => (
                        <div
                          className="plugin-outer"
                          key={plugin.plugin_id}
                          onClick={() => updatePluginHandler(plugin.plugin_id)}
                        >
                          <div className="plugin-inner">
                            <div className="plugin-image-outer">
                              <img src={plugin.logo} alt={plugin.name} />
                            </div>
                            <div className="plugin-details">
                              <div className="plugin-name">{plugin.name}</div>
                              <div className="plugin-description">
                                <span>{plugin.name}</span> simplifies HR, payroll, and employee management for businesses.
                              </div>
                            </div>
                            <button className={plugin.connected ? "connected-btn" : "connect-btn"}>
                              {plugin.connected ? "Connected" : "Connect"}
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="nav-buttons-outer">
                <button
                  className="nav-btn back-btn"
                  onClick={() => {
                    setActiveKey("tab4");
                  }}
                >
                  Back
                </button>
                <div></div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddLocationPopup
        level={getLevelName(newLocationDirectoryIndex)}
        show={showAddLocationPopup}
        onSubmit={SubmitAddLocationPopupHandler}
        onClose={onAddLocationPopupClose}
      />
      <AddBranchPopup
        name={newBranchLocationName}
        show={showAddBranchPopup}
        onSubmit={SubmitAddBranchPopupHandler}
        onClose={onAddBranchPopupClose}
      />
      <BranchDetailPopup
        data={branchDetailsData}
        show={showBranchDetailsPopup}
        onSubmit={SubmitBranchDetailsPopupHandler}
        onClose={onBranchDetailsPopupClose}
      />
      <DeleteLocationPopup
        name={deleteLocationName}
        level={getLevelName(deleteLocationDirectoryIndex)}
        show={showDeleteLocationPopup}
        onSubmit={SubmitDeleteLocationPopupHandler}
        onClose={onDeleteLocationPopupClose}
      />
    </div>
  );
};

export default NewOrgansation;
