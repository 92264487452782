import React from "react";
import BannerBg from "../../../assets/images/home-banner-img.png";

const HomeHeader = () => (
  <div className="layout-heading">
    <h2>Welcome Back, Admin</h2>
    <div>Let's finish your task today</div>
    <div className="banner-bg">
      <img src={BannerBg} alt="BannerImage" />
    </div>
  </div>
);

export default HomeHeader;
