import React from "react";

const LanguagesSection = () => {
  const languages = [
    { name: "English", level: "Fluent" },
    { name: "Spanish", level: "Intermediate" },
    { name: "Mandarin", level: "Basic" },
  ];

  return (
        <div className="right-lower-div">
          <div className="heading-languages">LANGUAGES</div>
          <div className="languages-data">
            {languages.map(({ name, level }, idx) => (
              <div className="languages-list" key={idx}>
                <div className="namelevel">
                  <div className="listitem-dot"></div>
                  <div className="lang-name-level">
                    <span className="language-name">{name}</span>
                    <span className="language-level">({level})</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default LanguagesSection;
