import React from "react";
import { Navbar, Container } from "react-bootstrap";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import UserDropdown from "../UserDropdown/UserDropdown";
// import "./Navbar.scss"; 

const CustomNavbar = ({ handleToggleSidebar, avatar, LogoutHandle,handleAccount }) => (
  <Navbar className="outer-landing-navbar">
    <Container className="landing-navbar">
      <HamburgerButton handleClick={handleToggleSidebar} />
      <Navbar.Collapse className="justify-content-end">
        <UserDropdown avatar={avatar} onLogout={LogoutHandle} handleAccount={handleAccount} />
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default CustomNavbar;
