import React from "react";
import CallIcon from "../../../assets/images/member-details/phone-icon.svg";
import MessageIcon from "../../../assets/images/member-details/message-icon.svg";
import NavigationArrowIcon from "../../../assets/images/member-details/navigation-arrow.svg";

const ProfilesSection = ({ profiles, onOpenModal }) => {
  return (
    <div className="member-details-part-five">
      <div className="profiles">
        <div className="heading-profiles">PROFILES</div>
        <div className="profiles-data">
          {profiles?.map((profile, idx) => (
            <div className="history" key={idx}>
              <div className="left-div">
                <div className="user-profile-image">
                  <img src={profile.image} alt="Profile" />
                </div>
                <div className="user-name">
                  {profile.name || "Not available"}
                </div>
                <div className="user-designation">{profile.designation}</div>
              </div>
              <div className="right-div">
                <div className="phone-number">
                  <div className="phone-icon">
                    <img src={CallIcon} alt="Call" />
                  </div>
                  <div className="number">{profile.phone_number}</div>
                </div>
                <div className="email">
                  <div className="email-icon">
                    <img src={MessageIcon} alt="email-icon" />
                  </div>
                  <div className="email-details">{profile.email}</div>
                </div>
                <div className="view-details">
                  <button onClick={() => onOpenModal(profile)}>
                    View Details
                    <span className="arrow">
                      <img src={NavigationArrowIcon} alt="navigation-arrow" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfilesSection;
