import Popup from "reactjs-popup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../app/hooks/useAuth";
import { AiOutlineLink } from "react-icons/ai"; // Import AiOutlineLink
import { MdShare } from "react-icons/md"; // Import MdShare
import "./styles/invite_member_share.scss";

export const MemberInvitedPopup = ({ link, errorMessage, show, onClose }) => {
  const user = useSelector(selectCurrentUser);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
      });
  };

  return (
    <div>
      <Popup
        modal
        className="add-invite-member-share-link-popup"
        open={show}
        onClose={onClose}
      >
        <button className="close-btn" onClick={onClose}>
          x
        </button>
        <div className="add-invite-member-share-link-outer">
          {errorMessage ? (
            <div className="popup-content-inner">
              <div className="popup-heading">
                <div className="popup-heading-inner">
                  <div className="popup-heading-1" style={{ color: "red" }}>
                    ERROR
                  </div>
                </div>
              </div>
              <div className="error-text">{errorMessage}</div>
            </div>
          ) : (
            <div className="popup-content-inner">
              <div className="popup-heading">
                <div className="popup-heading-icon">
                  {/* Icon only if there's no error */}
                  <MdShare />
                </div>
                <div className="popup-heading-inner">
                  <div className="popup-heading-1">Share Link</div>
                  <div className="popup-heading-2">Easily Share with Your Network</div>
                </div>
              </div>
              <div className="invite-member-share-link-name-outer">
                <div className="share-link-inner">
                  <AiOutlineLink className="link-icon" />
                  <div className="link">{link}</div>
                  <button
                    className="link-copy-btn"
                    onClick={handleCopy}
                    style={{ color: isCopied ? "#4894c5" : "grey" }}
                  >
                    {isCopied ? "Link Copied" : "Copy Link"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
};
