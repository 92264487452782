import React, { useState, useRef, useCallback } from 'react'; 
import Vector from "../../../assets/images/add-event/Vector.svg";
import PdfImage from "../../../assets/images/add-event/pdf_icon.svg";
import MovImage from "../../../assets/images/add-event/mov.svg";
import AviImage from "../../../assets/images/add-event/avi.svg";
import Mp4Image from "../../../assets/images/add-event/mp4.svg";
import PngImage from "../../../assets/images/add-event/png.svg";
import JpgImage from "../../../assets/images/add-event/jpg.svg";
import JpegImage from "../../../assets/images/add-event/jpeg.svg";
import './index.scss';
import { NotificationManager } from "react-notifications";

const DragAndDrop = ({ selectedFiles, setSelectedFiles,maxFileCount, maxFileSizeMB }) => {
    const [highlighted, setHighlighted] = useState(false);
    const fileInputRef = useRef(null);

    // const maxFileCount = maxFileCount;
    const maxFileSize = maxFileSizeMB * 1024 * 1024; // 20 MB
    const allowedVideoFormats = ['avi', 'mp4', 'mov'];
    const allowedImageFormats = ['jpg', 'jpeg', 'png'];
    const allowedDocumentFormats = ['pdf'];
    const allowedFormats = [...allowedVideoFormats, ...allowedImageFormats, ...allowedDocumentFormats];

    const handleFiles = useCallback((files) => {
        const filesArray = Array.from(files);
    
        let validFiles = [];
        let totalSize = selectedFiles?.reduce((sum, file) => sum + file.size, 0);
    
        for (const file of filesArray) {
            const fileExtension = file?.name.split('.').pop().toLowerCase();
            
            // Check file type
            if (!allowedFormats.includes(fileExtension)) {
                NotificationManager.error(`${file.name} is not an allowed file type.`);
                continue;
            }
    
            // Check individual file size
            if (file.size > maxFileSize) {
                NotificationManager.error(`${file.name} exceeds the maximum file size of 20 MB.`);
                continue;
            }
    
            // Check file count
            if (selectedFiles.length + validFiles.length >= maxFileCount) {
                NotificationManager.error(`You can upload a maximum of ${maxFileCount} files.`);
                break;
            }
    
            // Check cumulative size
            if (totalSize + file.size > 100 * 1024 * 1024) { // 100 MB
                NotificationManager.error(`Total file size cannot exceed 100 MB.`);
                break;
            }
    
            validFiles.push(file);
            totalSize += file.size;
        }
    
        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }, [setSelectedFiles, selectedFiles]);
    

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFiles(files);
        setHighlighted(false);
    }, [handleFiles]);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setHighlighted(true);
    }, []);

    const handleDragExit = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setHighlighted(false);
    }, []);

    const handleFileInputChange = useCallback((e) => {
        const files = e.target.files;
        handleFiles(files);
    }, [handleFiles]);

    const fileSize = (file) => {
        if (file.size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(file.size) / Math.log(k));
        return parseFloat((file.size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const getDocumentImage = (filename) => {
        const fileExtension = filename?.substring(filename.lastIndexOf('.') + 1).toLowerCase();

        switch (fileExtension) {
            case 'pdf':
                return <img className="document-image" src={PdfImage} alt="pdf" />;
            case 'jpg':
                return <img className="document-image" src={JpgImage} alt="jpg" />;
            case 'jpeg':
                return <img className="document-image" src={JpegImage} alt="jpeg" />;
            case 'png':
                return <img className="document-image" src={PngImage} alt="png" />;
            case 'mp4':
                return <img className="document-image" src={Mp4Image} alt="mp4" />;
            case 'avi':
                return <img className="document-image" src={AviImage} alt="avi" />;
            case 'mov':
                return <img className="document-image" src={MovImage} alt="mov" />;
            default:
                return null;
        }
    };

    const removeFileByIndex = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div
            className={`drag-and-drop ${highlighted ? 'highlighted' : ''}`}
        >
            <div
                className="drop-zone"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragExit}
                onClick={() => fileInputRef.current.click()}
            >
                <div className="drag-drop-outer">
                    <div className="drag-drop-inner">
                        <div className="file-image-outer">
                            <img
                                src={Vector}
                                alt="Attachment Icon"
                                className="attachment-icon"
                            />
                        </div>
                        <div className="drag-drop-details">
                            <div className="drag-drop-line1">
                                <span className="drag-drop">Upload a file</span>
                            </div>
                            <div className="drag-drop-line2">or click to browse ({maxFileSizeMB} MB max)</div>
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    className="file-input"
                    accept={allowedFormats.map(ext => `.${ext}`).join(',')}
                    multiple
                    onChange={handleFileInputChange}
                />
            </div>
            {selectedFiles.length > 0 && (
                <div className="file-list">
                    <div className="file-list-header">{selectedFiles.length} files added</div>
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="file-item">
                            <div className="file-image-outer">{getDocumentImage(file.name)}</div>
                            <div className="file-details">
                                <div className="filename">{file.name}</div>
                                <div className="file-size">{fileSize(file)}</div>
                            </div>
                            <button className="close-btn" onClick={() => removeFileByIndex(index)}>x</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DragAndDrop;
