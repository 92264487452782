import React from "react";
import OverviewCard from "../../../components/common/OverviewCard/OverviewCard";

const CounterOverview = ({ dashboardData }) => (
  <div className="counter-outer">
    <div className="counter-heading">Overview</div>
    <div className="row" style={{ margin: "0px" }}>
      <OverviewCard
        heading="Total Employees"
        count={dashboardData?.data?.members_count || 0}
        subHeading="Employees on Board"
        className="employee-counter-outer"
      />
      <OverviewCard
        heading="Channels"
        count={dashboardData?.channel_count || 0}
        subHeading="Channels Used"
        className="channel-counter-outer"
      />
    </div>
  </div>
);

export default CounterOverview;
