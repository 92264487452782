import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({ url: "/accounts/get_user_data/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getOrganisationMembers: builder.query({
      query: ({ search, sort_by }) => {
        let url = "/organisation/organisation_members/?";
        if (search !== "") {
          url += `search_keyword=${search}&`;
        }
       
        if (sort_by !== "" && sort_by !== undefined) {
          url += `sort_by=${sort_by}&`;
        }
        return { url: url, method: "get" };
      },
      keepUnusedDataFor: 0,
    }),
    getFailedUploadMembers: builder.query({
      query: () => ({
        url: "/organisation/failed_member_uploads/",
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getDashboardData: builder.query({
      query: () => ({ url: "/organisation/dashboard_data/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getUserProfile: builder.query({
      query: () => ({ url: "/accounts/profile/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getUserAccount: builder.query({
      query: () => ({ url: "/accounts/account-details/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocations: builder.query({
      query: ({ parent_id }) => {
        if (parent_id !== null) {
          return {
            url: `/organisation/locations/?parent_id=${parent_id}/`,
            method: "get",
          };
        } else {
          return { url: `/organisation/locations/`, method: "get" };
        }
      },
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocationBranches: builder.query({
      query: ({ parent_id }) => ({
        url: `/organisation/location_branches/${parent_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getChannelDetails: builder.query({
      query: ({ group_id }) => ({
        url: `/organisation/group_details/${group_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getUserDetails: builder.query({
      query: ({ user_id }) => ({
        url: `/accounts/get_user_data/${user_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getMyDocuments: builder.query({
      query: () => ({ url: `/accounts/documents/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getAttendanceEmployees: builder.query({
      query: () => ({ url: `/attendance/employee_list/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getAttendanceEmployeesHistory: builder.query({
      query: (id) => ({
        url: `/attendance/employee_attendance_history/${id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getDocumentCategory: builder.query({
      query: () => ({ url: `/accounts/document_categories/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
   
    getCalendarEvents: builder.query({
      query: ({ month, year }) => ({ url: `/calender/calendar_list/?month=${month}&year=${year}`, method: 'get' }),
      keepUnusedDataFor: 0
    }),
    getPlugins: builder.query({
      query: () => ({ url: `/plugins/plugins/`, method: 'get' }),
      keepUnusedDataFor: 0
    }),
    updatePlugin: builder.mutation({
      query: (data) => ({
        url: "/plugins/plugins/", 
        method: "PUT",
        data, 
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: "/accounts/profile/",
        method: "PUT",
        data, 
      }),
    }),
    createCalendarEvent: builder.mutation({
      query: (data) => ({
        url: "/calendar_manager/calendarevents/",
        method: "POST",
        data, 
      }),
    }),
    deleteCalendarEvent: builder.mutation({
      query: (event_id) => ({
        url: `/calendar_manager/calendarevents/${event_id}/`, 
        method: "DELETE",
      }),
    }),
    updateOrganisations: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/update_organisation/", 
        method: "PUT",
        data: userFormData, 
      }),
    }),
    removeParticipants: builder.mutation({
      query: (data) => ({
        url: "/organisation/participant/", 
        method: "DELETE",
        data, 
      }),
    }),
    updateParticipantPermission: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_permissions/", 
        method: "POST",
        data, 
      }),
    }),
    createOrganisationMembers: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/create_members/",
        method: "POST",
        data: userFormData, 
      }),
    }),
    createUploadDocument: builder.mutation({
      query: (data) => ({
        url: "/accounts/document_upload/", 
        method: "POST",
        data, 
      }),
    }),
    deleteDocument: builder.mutation({
      query: (document_id) => ({
        url: `/accounts/document_delete/${document_id}/`, 
        method: "DELETE",
      }),
    }),
    createLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", 
        method: "POST",
        data, 
      }),
    }),
    sendBroadcast: builder.mutation({
      query: (data) => ({
        url: "/organisation/send_broadcast/", 
        method: "POST",
        data,
      }),
    }),
    deleteLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", 
        method: "DELETE",
        data, 
      }),
    }),

    createBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", 
        method: "POST",
        data, 
      }),
    }),

    getOrganisationBranch: builder.query({
      query: () => ({ url: `/organisation/branch/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    
    updateChannelMessage: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_group_message/",
        method: "POST",
        data,
      }),
    }),

    deleteBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", 
        method: "DELETE",
        data, 
      }),
    }),
    
    getUserOrganisations: builder.query({
      query: () => ({ url: `/chats/user_organisation_list/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),

    getOrganisationSitemap: builder.query({
      query: (organisation_id) => {
        return { url: `/chats/hierarchy/${organisation_id}/`, method: "get" };
      },
      keepUnusedDataFor: 0,
    }),

    getOrganisationSitemapByLocation: builder.query({
      query: ({ organisation_id, location_id }) => {
       

        return {
          url: `/chats/hierarchy/${organisation_id}/${location_id}/`,
          method: "get",
        };
      },
      keepUnusedDataFor: 0,
    }),

    deleteAccount: builder.mutation({
      query: () => ({
        url: "/accounts/deactivate-user/", 
        method: "POST",
      }),
    }),
    inviteMemberToLocation: builder.mutation({
      query: (data) => ({
        url: "/chats/sendchatlink/", 
        method: "POST",
        data,
      }),
    }),

    createEvent: builder.mutation({
      query: (eventData) => ({
        url: "eventmanagement/eventscreate/",
        method: "POST",
        data: eventData,
        formData: true,
      }),
    }),
 
    fetchEvents: builder.query({
      query: (page = 1) => `eventmanagement/eventscreate/?page=${page}`,
    }),


    updateEvent: builder.mutation({
      query: ({ eventId, eventData }) => ({
        url: `eventmanagement/eventscreate/${eventId}/`,
        method: "PUT",
        body: eventData,
        formData: true,
      }),
    }),
    createAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/create_announcement/",
        method: "POST",
        data
      }),
    }),

    updateAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/update_announcement/", 
        method: "PUT",
        data, 
      }),
    }),

    feedbackAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/announcement_feedback/",
        method: "POST",
        data
      }),
    }),

    readAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/announcement_read/",
        method: "POST",
        data
      }),
    }),

    getAnnouncementDetails: builder.query({
      query: (announcement_id) => ({
        url: `/announcement/announcement_detail/${announcement_id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getCreateFcmToken: builder.query({
      query: ({ device_id, token }) => ({
        url: `/announcement/get_create_fcm_token/`,
        method: 'GET',
        params: {
          device_id,
          token,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getAnnouncementList: builder.query({
      query: () => ({
        url: '/announcement/announcement_list/',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getFeedbackDetails: builder.query({
      query: (feedback_id) => ({
        url: `/announcement/get_feedbackdetails/${feedback_id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getMembersList: builder.query({
      query: ({organisation_id, keyword}) => ({
        url: `/chats/members/${organisation_id}/${keyword}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetOrganisationLocationsQuery,
  useGetOrganisationMembersQuery,
  useGetUserProfileQuery,
  useGetUserAccountQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useDeleteBranchMutation,
  useRemoveParticipantsMutation,
  useDeleteDocumentMutation,
  useUpdateParticipantPermissionMutation,
  useGetChannelDetailsQuery,
  useUpdateOrganisationsMutation,
  useCreateUploadDocumentMutation,
  useGetDocumentCategoryQuery,
  useGetMyDocumentsQuery,
  useCreateOrganisationMembersMutation,
  useCreateBranchMutation,
  useGetOrganisationLocationBranchesQuery,
  useSendBroadcastMutation,
  useGetCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useGetFailedUploadMembersQuery,
  useGetOrganisationBranchQuery,
  useUpdateChannelMessageMutation,
  useDeleteAccountMutation,
  useGetUserDetailsQuery,
  useGetDashboardDataQuery,
  useGetPluginsQuery,
  useUpdatePluginMutation,
  useGetAttendanceEmployeesQuery,
  useGetAttendanceEmployeesHistoryQuery,
  useUpdateUserProfileMutation,
  useGetUserOrganisationsQuery,
  useGetOrganisationSitemapQuery,
  useGetOrganisationSitemapByLocationQuery,
  useLazyGetOrganisationSitemapByLocationQuery,
  useInviteMemberToLocationMutation,
  useCreateAnnouncementMutation,
  useFeedbackAnnouncementMutation,
  useReadAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useGetAnnouncementDetailsQuery,
  useGetCreateFcmTokenQuery,
  useGetAnnouncementListQuery,
  useGetFeedbackDetailsQuery,
  useCreateEventMutation,
  useFetchEventsQuery,
  useUpdateEventMutation,
  useLazyGetMembersListQuery,
} = usersApiSlice;
