import React, { useState, useEffect } from 'react';
import LocationNode from './LocationNode';
import './styles.scss';

const LocationTree = ({data, SubmitAddLocationHandler, SubmitDeleteLocationHandler, SubmitAddBranchHandler, SubmitBranchDetailHandler}) => {
const [treeData, setTreeData] = useState(data && {});

  useEffect(() => {
    
    setTreeData(data);
  }, [data]);

  if (!treeData) {
    return <div className="loading">Loading locations...</div>;
  }
  return (
    <div className="location-tree">
      {data && <LocationNode location={treeData} SubmitAddLocationHandler={SubmitAddLocationHandler} SubmitDeleteLocationHandler={SubmitDeleteLocationHandler} SubmitAddBranchHandler={SubmitAddBranchHandler} SubmitBranchDetailHandler={SubmitBranchDetailHandler}/>}
    </div>
  );
};

export default LocationTree;
