import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "../../features/users/usersApiSlice";
import AvatarComponent from "./AvatarComponent/AvatarComponent";
import BasicInfo from "./BasicInfo/BasicInfo";
import EducationSection from "./EducationSection/EducationSection";
import CertificationSection from "./CertificationSection/CertificationSection";
import ExperienceSection from "./ExperienceSection/ExperienceSection";
import AwardsSection from "./AwardsSection/AwardsSection";
import SkillsSection from "./SkillsSection/SkillsSection";
import PersonalWebsite from "./PersonalWebsite/PersonalWebsite";
import LanguagesSection from "./LanguagesSection/LanguagesSection";
import ConferenceSection from "./ConferenceSection/ConferenceSection";
import ProjectsSection from "./ProjectsSection/ProjectsSection";
import ProfilesSection from "./ProfilesSection/ProfilesSection";
import ModalMemberDetails from "../../components/popups/ModalMemberDetails";
import NavigationArrowIcon from "../../../src/assets/images/member-details/navigation-arrow.svg";
import "./styles.scss";

const MemberDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const { userId } = useParams();
  const { data: userData, refetch } = useGetUserDetailsQuery({ user_id: userId });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const firstName = userData?.data?.first_name || "";
  const lastName = userData?.data?.last_name || "";
  const fullName = `${firstName} ${lastName}`.trim();

  const handleOpenModal = (profile) => {
    setSelectedMember(profile);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null);
  };

  return (
    <section className="layout-inner-member-details">
      <div className="current-page">
        <div className="key">
          Members
          <span className="arrow-image">
            <img src={NavigationArrowIcon} alt="Navigation arrow" />
          </span>
        </div>
        <div className="value">
          {fullName
            ? `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
                lastName.charAt(0).toUpperCase() + lastName.slice(1)
              }`
            : "Not available"}
        </div>
      </div>
      <div className="member-detail-container-outer">
        <AvatarComponent userData={userData} fullName={fullName} />
        <BasicInfo userData={userData} />
        <div className="member-details-part-three">
        <div className="left-div-three">
          <EducationSection educationData={userData?.data?.education} />
          <CertificationSection certifications={userData?.data?.certifications} />
          </div>
          <div className="middle-div-three">
          <ExperienceSection experiences={userData?.data?.experiences} />
          <AwardsSection awards={userData?.data?.awards} />
          </div>
          <div className="right-div-three">
          <SkillsSection skills={userData?.data?.skills} />
          <PersonalWebsite website={userData?.data?.personal_website} />
          <LanguagesSection languages={userData?.data?.languages} />
          </div>
        </div>
        <div className="member-details-part-four">
          <ConferenceSection conferences={userData?.data?.conferences} />
          <ProjectsSection projects={userData?.data?.projects} />
          </div>
          <ProfilesSection
            profiles={userData?.data?.profiles}
            onOpenModal={handleOpenModal}
          />
        {showModal && (
          <ModalMemberDetails selectedMember={selectedMember} onClose={handleCloseModal} />
        )}
      </div>
    </section>
  );
};

export default MemberDetails;
