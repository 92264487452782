import React from "react";

const CalendarHeader = ({ currentView, onChangeView }) => {
  return (
    <div className="calendar-header">
      <button
        className={currentView === "day" ? "active" : ""}
        onClick={() => onChangeView("day")}
      >
        Day
      </button>
      <button
        className={currentView === "week" ? "active" : ""}
        onClick={() => onChangeView("week")}
      >
        Week
      </button>
      <button
        className={currentView === "month" ? "active" : ""}
        onClick={() => onChangeView("month")}
      >
        Month
      </button>
    </div>
  );
};

export default CalendarHeader;
