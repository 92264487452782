import React from 'react';
import moment from 'moment';
import './dayview.scss'

const DayView = ({ filters, data, selectedDate }) => {
  const filteredData = (type) => {
    return data[type].filter(item => moment(item.date).isSame(selectedDate, 'day'));
  };

  return (
    <div className="day-view">
  
      <div className="day-events">
        {filters.meetings &&
          filteredData('meetings').map(meeting => (
            <div className="event-item" key={meeting.calendar_id}>
              <p>{meeting.title}</p>
              <span>{meeting.time}</span>
            </div>
          ))
        }

        {filters.events &&
          filteredData('events').map(event => (
            <div className="event-item" key={event.calendar_id}>
              <p>{event.title}</p>
              <span>{event.time}</span>
            </div>
          ))
        }

        {filters.announcements &&
          filteredData('announcements').map(announcement => (
            <div className="event-item" key={announcement.calendar_id}>
              <p>{announcement.announcement_fk.title}</p>
              <span>{announcement.announcement_fk.scheduled_time}</span>
            </div>
          ))
        }

        {(!filters.meetings && !filters.events && !filters.announcements) ||
        (filteredData('meetings').length === 0 &&
          filteredData('events').length === 0 &&
          filteredData('announcements').length === 0) ? (
          <p>No events for this day.</p>
        ) : null}
      </div>
    </div>
  );
};

export default DayView;
