import React from "react";

const InputField = ({
  id,
  label,
  type = "text",
  value,
  placeholder,
  onChange,
  icon,
  className,
  inputClassName,
  name,
  maxLength,
  lableClassName,
  ...rest
}) => {
  return (
    <>
      {label && <label htmlFor={id} className={lableClassName}>{label}</label>}
      <div className={className}>
        {icon && <div className="icon">{icon}</div>}
        {type === "textarea" ? (
          <textarea
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
            {...rest}
            className={inputClassName}
          />
        ) : (
          <input
            type={type}
            id={id}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
            {...rest}
            className={inputClassName}
          />
        )}
      </div>
    </>
  );
};

export default InputField;
