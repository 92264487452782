import React from "react";
import EmployeeMonthIcon from "../../../assets/images/member-details/employee-month-icon.svg";
import TopInnovatorIcon from "../../../assets/images/member-details/top-innovator-icon.svg";

const AwardsSection = () => {
  const awards = [
    {
      title: "Employee of the Year",
      event: "Tech Solutions, 2022",
      description: "Recognized for exceptional contributions to the product engineering team.",
      icon: EmployeeMonthIcon,
    },
    {
      title: "Top Innovator Award",
      event: "InnovateX Hackathon, 2018",
      description: "Awarded for creating a prototype AI assistant.",
      icon: TopInnovatorIcon,
    },
  ];

  return (
        <div className="middle-lower-div">
          <div className="heading-awardsrecognitions">
            AWARDS & RECOGNITIONS
          </div>
          <div className="awardsrecognition-data">
            {awards.map(({ title, event, description, icon }, idx) => (
              <div className="history" key={idx}>
                <div className="left-div">
                  <img src={icon} alt="Award Icon" />
                </div>
                <div className="right-div">
                  <div className="title">{title}</div>
                  <div className="event-name">{event}</div>
                  <div className="description">{description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default AwardsSection;
