import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apiSlice } from "./apiSlice";
import authReducer from "../../app/hooks/useAuth";
import channelSlice from "../../features/channels/channelSlice";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// Combine all reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer, // API slice for RTK Query
  auth: authReducer, // Authentication slice
  channel: channelSlice, // Channel-related state
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Define ignored actions for serializable check
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions,
      },
    }).concat(apiSlice.middleware), // Add API middleware for RTK Query
  devTools: process.env.NODE_ENV !== "production", // Enable devTools only in development
});
