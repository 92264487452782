import React from "react";
import AsyncSelect from "react-select/async";
import { FaTimes } from "react-icons/fa";
import "./MemberSelect.scss";

const MemberSelect = ({ selectedMembers, setSelectedMembers, loadOptions }) => {
  const handleMemberSelect = (selectedOptions) => {
    const newMember = selectedOptions.slice(-1)[0];
    if (
      newMember &&
      !selectedMembers.find((m) => m.value === newMember.value)
    ) {
      setSelectedMembers((prevMembers) => [...prevMembers, newMember]);
    }
  };

  const removeMember = (memberToRemove) => {
    setSelectedMembers((prevMembers) =>
      prevMembers.filter((member) => member.value !== memberToRemove.value)
    );
  };

  const formatOptionLabel = ({ label, profile_image }) => (
    <div className="option-label">
      <img className="members-profile-picture" src={profile_image} alt={label} />
      <div className="option-label-text">{label}</div>
    </div>
  );

  return (
    <div className="event-input">
      <label>Members</label>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        value={[]}
        loadOptions={loadOptions}
        formatOptionLabel={formatOptionLabel}
        onChange={handleMemberSelect}
      />
      <div className="members-images">
        {selectedMembers.slice(0, 3).map((member, index) => (
          <div key={index} className="member-image-wrapper">
            <img
              src={member.profile_image}
              alt={member.label}
              className="member-profile-image"
            />
            <FaTimes
              className="remove-icon"
              onClick={() => removeMember(member)}
            />
          </div>
        ))}
        {selectedMembers.length > 3 && (
          <div className="main-member-count">
            <span className="member-count">
              +{selectedMembers.length - 3}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberSelect;
