import React from "react";
import PropTypes from "prop-types";

const Image = ({ src, alt, className, style, fallbackSrc }) => {
  const handleError = (e) => {
    if (fallbackSrc) {
      e.target.src = fallbackSrc;
    }
  };

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      style={style}
      onError={handleError}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  fallbackSrc: PropTypes.string, // Optional fallback image
};

Image.defaultProps = {
  className: "",
  style: {},
  fallbackSrc: "",
};

export default Image;
