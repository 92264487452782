import { Link } from "react-router-dom";
import { CgEnter } from "react-icons/cg";
import { Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useGetAttendanceEmployeesQuery } from "../../features/users/usersApiSlice";
import { React, useEffect } from "react";
import "./styles.scss";

const Attendance = () => {
  const {
    data: employeesRefetchData,
    error,
    refetch,
  } = useGetAttendanceEmployeesQuery();

  const tableStyle = {
    borderRadius: "8px",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    margin: "10px",
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: "none",
  };

  const refetchHandler = async () => {
    const resp = await refetch();
    if (!resp.data || resp.error) {
      NotificationManager.error(
        error?.data?.message || "Failed to fetch employee atendance details.",
        "Error"
      );
    }
  };

  useEffect(() => {
    refetchHandler();
  }, []);

  return (
    <section className="layout-inner">
      <div className="attendance-container-outer">
        <div className="heading-outer">
          <div className="page-heading">Attendance </div>
        </div>
        {employeesRefetchData && (
          <div className="attendance-outer">
            <Table className="table" style={tableStyle}>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {employeesRefetchData.data.map((candidate, index) => (
                  <tr key={index} style={rowStyle}>
                    <td style={cellStyle}>{candidate.first_name}</td>
                    <td style={cellStyle}>{candidate.last_name}</td>
                    <td style={cellStyle}>{candidate.email}</td>
                    <td style={{ ...cellStyle, fontWeight: "bold" }}>
                      <div className="action-btns">
                        {candidate.user_id !== null && (
                          <Link
                            to={`/attendance-history/${candidate.user_id}/`}
                          >
                            <button className="action-btn">
                              <CgEnter />
                            </button>
                          </Link>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </section>
  );
};

export default Attendance;
